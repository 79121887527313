.table {
  &-row {
    &-header {
      position: relative;
    }
  }

  &-cell {
    padding: 0$mgod;

    > div {
      position: relative;
      padding: .75rem 1rem;
      
      @media (min-width: 576px) {
        // padding-left: .4rem;
        // padding-right: .4rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      // @media (min-width: 992px) {
      //   padding-left: 1rem;
      //   padding-right: 1rem;
      // }

      height: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        height: 1px;
        width: 100%;
        background-repeat: repeat-x;
        background-position: bottom left;
        background-size: 6px 1px;
        background-image: linear-gradient(to right, #d4d4d4 4px, transparent 4px);
      }

      span {
        font: 400 0.875rem $font-stack-open; 
        @media (min-width: 992px) {
          font-size: 0.9375rem;
        }

        &.price {
          font: 700 1.5rem $font-stack-rubik;
          &:before {
            content: "$";
            font-size: 50%;
            font-weight: inherit;
            top: -.5rem;
            position: relative;
          }

          small {
            font-size: 1rem;
            font-weight: inherit;
          }
        }
      }
    }
    
    &:not(.cell-name):not(.cell-label){
      > div {
        background-color: #fff;
        text-align: center;
      }
    }

    &.cell-name {
      > div {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        // z-index: 30;
      }
      span {
        font-weight: 700;
      }
      i {
        color:#1bddee;
      }

      @media (min-width: 576px) {
        span {
          padding-left: 5px;
        }
        z-index: 38;
      }
    }

    &.action {
      a {
        margin: 5px 0; 
        color: #fff;
        display: block;
        padding: .75rem 0;
        font: 700 1rem $font-stack-open; 
        @media (min-width: 992px) {
          font-size: 1.125rem;
        }
      }
    }

    &.empty {
      display: none;

      @media (min-width: 576px) {
        display: table-cell;
        @include empty-td;
      }
    }

    @media (min-width: 576px) {
      padding: 0 5px$mgod;
      width: 222px;
    }
  }

  &-compare {
    &-wrapper {
      margin-bottom: -1rem;
      padding-bottom: 1rem;

      .floater-header-compare {
        &.floated {
          table {
            .cell-header > div {
              @include border-radius(0$mgod);
            }
          }
        }

        &:before {
          // content: "";
          background-color: red;
          // background-color: #DDE8F6;
          position: absolute;
          height: .25rem;
          width: 100%;
          // z-index: 34;
          top: 0;
        }
      }

      .table-responsive {
        &:before {
          @media (max-width: 575.98px) {
            // content: "";
            height: 95px;
            width: 100%;
            background-color: red;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 36;
          }
        }

        @media (min-width:1200px) {
          overflow-x: hidden;
        }
        
      }

      
    }

    .cell {
      &-header {
        border-bottom: none;

        > div {
          &:after {
            content: none;
          }

          @include _border-radius(9px$mgod, 9px$mgod, 0, 0);
          border-bottom: 2px solid #d5d7de;
        }
        
        text-align: center;
      }

      &-footer {
        > div {
          @include _border-radius(0, 0, 9px, 9px);
        }
      }

      &-name {
        > div {
          i {
            @media (max-width: 575.98px) { display: none; }
          }
        }
      }

      &-arrow {
        position: absolute;
        z-index: 2;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%$mgod;
        top: 50%;

        .arrow {
          color: #b6b8c4;
          &:hover {
            cursor: pointer$mgod;
            color: #18dded;
          }

          position: absolute;

          &.f {
            left: 26px;
          }

          &.l {
            right: 26px;
          }
        }

        @media (min-width:576px) {
          display: none;
        }
      }

      &-label {
        > div {
          padding:1.5rem 0;
          span { 
            font: 700 1.25rem $font-stack-rubik; color:#092a44; 
            @media(min-width: 576px) {
              font-size: 1.5rem;
            }
          }
        }
      }

     
    }

    thead {
      th {

        > div {
          padding: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          @media (max-width: 575.98px) {
            &.table-cell {
              background-color: #fff;
            }
            &.cell-label {
              background-color: transparent;
              text-align: center;
              > div {
                padding-top: 1rem;
                padding-bottom: 1rem;
              }
            }
          }
        }

        &:first-child {
          td {
            @include empty-td;
          }
        }
      }
    }

    .plan {
      padding-bottom: 0$mgod;
      padding-top: 1.25rem;
      // remove hover effect
      @include transition(none$mgod);
      &:hover {
        transform: scale(1)$mgod;
      }

      h5 {
        margin-bottom: 1rem;
      }
    }
  }
}



.tooltip {
  opacity: 1$mgod;

  &-table-compare {display: none}

  @media(min-width: 576px) {
    &-inner {
      background-color: #181d35;
      padding: .6rem .75rem;
      color:#595f7c;
      // word-break: break-all;
      text-align: left;

      font: 400 0.8125rem $font-stack-open;

      p {
        margin-bottom: .25rem;
        font-weight: 600;
        font-size: .875rem;
        color:#fff;
      }
    }

    &-table-compare {
      display: block;
      left: 15px$mgod;
      top:-5px$mgod;

      .tooltip {
        &-inner {
          max-width: 215px;
          /* If max-width does not work, try using width instead */
          width: 215px; 
        }
      }

      .arrow {
        left: 18px$mgod;
        &:before {
          border-top-color: #181d35;
        }
      }
    }
  }
}