.features {
   padding-bottom: 3rem; 
   background-color: #fff;

   @media (min-width: 1200px) {
      padding-bottom: 12.75rem;
   }

   h3 {
      &.section {
         &--title {
            padding-top: 5rem;
         }
      }
   }

   .fig {
      padding: 1.25rem;
      margin-bottom: 1rem;

      span {
         &.icon {
            height: 62px;

            &--fig1 {
               background-image: url($dir_support + '/24-7-365-Support.svg');
            }
            &--fig2 {
               background-image: url($dir_support + '/45-Day-Guarantee.svg');
            }
            &--fig3 {
               background-image: url($dir_support + '/Uptime-Guarantee.svg');
            }

            &--fig4 {
               background-image: url($dir_support + '/Fast-Load-Time.svg');
            }
            &--fig5 {
               background-image: url($dir_support + '/Included-Apps.svg');
            }
            &--fig6 {
               background-image: url($dir_support + '/Flexibility.svg');
            }

            &--fig7 {
               background-image: url($dir_support + '/DDoS-Protection.svg');
            }
            &--fig8 {
               background-image: url($dir_support + '/Real-Cloud.svg');
            }
            &--fig9 {
               background-image: url($dir_support + '/Powerful-Tools.svg');
            }
            &--fig10 {
               background-image: url($dir_support + '/fet-fast-validation.svg');
            }

            &--fig11 {
               background-image: url($dir_support + '/fet-encryption.svg');
            }
            &--fig12 {
               background-image: url($dir_support + '/fet-site-seal.svg');
            }
            &--fig13 {
               background-image: url($dir_support + '/fet-trusted-by-all-browsers.svg');
            }

            &--fig14 {
               background-image: url($dir_support + '/fet-45-day-guarantee.svg');
            }
            &--fig15 {
               background-image: url($dir_support + '/fet-expert-assistance.svg');
            }
            &--fig16 {
               background-image: url($dir_support + '/fet-scalability.svg');
            }
            &--fig17 {
               background-image: url($dir_support + '/fet-powerful-tools.svg');
            }
         }
      }

      h6 {
         margin-bottom: 0;         
         padding: 1.25rem 0;
      }

      .desc {
         border-top: 1px solid #e1eaf8;
         padding-top: .75rem;
         margin: 0 -.5rem;
      }
   }

   
}