#domainTabs {
   border: 0;
   margin-bottom: 50px;

	li {margin-bottom: 3px;}

   a {
      border: 0;

      &.active {
         background-color: transparent;

         span {
				padding-bottom: .25rem;
            border-bottom: 3px solid $color-3;
         }
      }
   }
}


.table {
   &-tld {
      //   margin-bottom: 1rem;

      .table {
         margin-bottom: 0;
      }

      @include border-radius(9px);

      thead {
         background-image: linear-gradient(to right, #000066, #010e73, #011b80, #01278c, #003399);

         th {
            border-width: 0;
            color: #fff;
         }
      }

      td,
      th {
         padding-top: 1rem;
         padding-bottom: 1rem;
         border: 0;
         text-align: center;
      }

      td {
         position: relative;

         &:after {

            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0;
            height: 1px;
            width: 100%;
            background-repeat: repeat-x;
            background-position: bottom left;
            background-size: 6px 1px;
            background-image: linear-gradient(to right, #ccd9dd 4px, transparent 4px);

         }

         font-family: $font-stack-rubik;
         font-size: 1.5rem;
         font-weight: 700;
      }

      tbody {
         background-color: #fff;

         tr {
            &:last-child {
               td {
                  &:after {
                     content: none;
                  }
               }
            }
         }
      }
   }
}