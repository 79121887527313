// Rounded corners	
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}


@mixin _border-radius($tl, $tr, $bl, $br) {
  // top left radius
  -webkit-border-top-left-radius: $tl;
  -moz-border-radius-topleft: $tl;
  border-top-left-radius: $tl;

  // top right radius
  -webkit-border-top-right-radius: $tr;
  -moz-border-radius-topright: $tr;
  border-top-right-radius: $tr;

  // bottom left radius
  -webkit-border-bottom-left-radius: $bl;
  -moz-border-radius-bottomleft: $bl;
  border-bottom-left-radius: $bl;

  // bottom right radius
  -webkit-border-bottom-right-radius: $br;
  -moz-border-radius-bottomright: $br;
  border-bottom-right-radius: $br;
}


// Box Shadow
@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}

@mixin gradient($from, $to) {
	background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background: -moz-linear-gradient(top,  $from,  $to);
	// filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr=$from,endColorstr=$to);
}

// Input Placeholder color (https://css-tricks.com/almanac/selectors/p/placeholder)
@mixin input_placeholder($color) {
	&::-webkit-input-placeholder { color: $color; }
	&::-moz-placeholder { color: $color; }
	&:-ms-input-placeholder { color: $color; }
	&:-moz-placeholder { color: $color; }
}

@mixin transition($param) {
	-webkit-transition: $param;
	-moz-transition: $param;
	-o-transition: $param;
	-khtml-transition: $param;
	transition: $param;
}

@mixin empty-td {
	> div {
		&:after {
		  content: none;
		}
	}
}