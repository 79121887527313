.banner {
  background-image: url(/assets/images/bg-migration.jpg);

  .content {
    .title {
      padding: 2.5rem 0 1.125rem;
      &-sub {
        font-family: $font-stack-rubik;
        margin-bottom: 1.5rem;
        font-weight: 400;
      }
    }

    &.list {
      li {
        margin-bottom: .5rem;
        color:#1bddee;
      }
    }

    &--btn-wrapper {
      width: 100%;
      padding-bottom: 3rem;
    }
  }

  @media (min-width:576px) {
    min-height: 22.5rem;
    margin-top: 2.5rem;
    .content {
      .title {
        font-size: 2.8125rem;
        padding: 3.125rem 0 0;

        &-sub {
          font-size: 1.875rem;
          margin-bottom: 1.75rem;
        }
      }

      &--btn-wrapper {
        width: auto;
        display: inline-flex;
        
      }
    }
  }  

  .section--title {
    margin: 0;
  }

  // Banner Styling
  @media (min-width:992px) {
    &--domain-search {
      background-image: url(/assets/images/domain-banner-bg.jpg);
    }

    &--web-hosting {
      background-image: url(/assets/images/web-hosting-bg.jpg);
    }

    &--ssl-certificates {
      background-image: url(/assets/images/ssl-certificates-bg.jpg);
    }

    &--web-transfer {
      background-image: url(/assets/images/web-hosting-transfer-bg.jpg);
    }

    &--wow-private-server {
      background-image: url(/assets/images/wow-private-server-hosting-bg.jpg);
    }

    &--about-us {
      background-image: url(/assets/images/about-banner-bg.jpg);
    }

    &--vps-hosting {
      background-image: url(/assets/images/virtual-private-servers-bg.jpg);
    }
    
  
    
  }

  &--domain-search {
    .section--title {
      font-size: 1.5rem;

      padding-top: 2.75rem;
      padding-bottom: 1.75rem;

      @media (min-width:576px) {
        padding: 4.5rem 0 2.25rem;
        font-size: 2rem;
      }
      
      @media (min-width:992px) {
        font-size: 2.1875rem;
      }

      font-weight: 600;
      color:#fff;

      span {
        color:$color-1;
      }
    }
  }


}