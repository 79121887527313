* {
  -webkit-overflow-scrolling: touch;
}

body {
  color: #333;
  padding-top: 70px;
  overflow-x: hidden;
}

body:before {
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

body.dropdown-active:before {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 768px) {
  body {
    padding-top: 80px;
  }
}

@media (min-width: 1200px) {
  body {
    padding-top: 90px;
  }
}

.form-control:focus {
  border-color: #aaa;
}

.btn:focus, .btn.focus, .btn:active, .form-control:focus, .form-control.focus, .form-control:active, a:focus, a.focus, a:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.icon:before, .icon:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.check-square:before {
  font-family: "Font Awesome 5 Free";
  content: "\f14a";
}

.check-square-solid li:before {
  font-weight: 900;
}

.check-square-solid li:before, .check-square li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f14a";
}

.block--padding {
  padding: 3.125rem 0;
}

@media (min-width: 576px) {
  .block--padding {
    padding: 5rem 0;
  }
}

.wrapper-inline {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.wrapper-inline-content-1 {
  padding-bottom: 18.75%;
}

.select-container {
  position: relative;
}

.select-container .select-arrow {
  color: #092a44;
  right: 0px;
  top: 17px;
  width: 30px;
  position: absolute;
  display: block;
  z-index: 10;
  margin: 0 0 0 0;
  pointer-events: none;
}

.row--content {
  margin-right: -10px;
  margin-left: -10px;
}

.row--content [class^=col], .row--content [class^=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

.item-title {
  font: 700 1.5rem "Rubik", sans-serif;
  color: #092a44;
}

.section--title {
  font: 700 1.5rem "Rubik", sans-serif;
  padding: 2.5rem 0;
  color: #092a44;
}

.section--wrapper .block--title {
  margin: 0;
  padding-top: 0;
  padding-bottom: 2rem;
  font: 700 2.5rem "Rubik", sans-serif;
}

.section--wrapper p, .section--wrapper span {
  font-family: "Open Sans", sans-serif;
}

.section--wrapper p {
  margin-bottom: 2rem;
}

.section--wrapper p:last-child {
  margin: 0;
}

@media (min-width: 576px) {
  .section--title {
    font-size: 2.5rem;
  }
}

.content-fh {
  height: 100%;
}

.content .title {
  font: 700 1.5rem "Rubik", sans-serif;
  margin: 0;
}

.content .title-sub {
  font-size: 1.125rem;
  display: block;
  width: 100%;
}

.content p, .content span, .content li {
  font-family: "Open Sans", sans-serif;
}

.content p {
  margin-bottom: 2rem;
  font-size: 1rem;
}

.content p:last-child {
  margin: 0;
}

.content.list ul {
  color: #092a44;
  padding: 0 1rem;
}

.content.list ul .list-title {
  font: 700 1.125rem "Rubik", sans-serif;
  margin-bottom: 2rem;
  display: inline-block;
  width: 100%;
}

.content.list li {
  padding-left: 35px;
  position: relative;
  margin: 0 0 1.35rem;
  font: 400 1rem "Rubik", sans-serif;
}

.content.list li:before {
  display: inline-block;
  position: absolute;
  color: #1BDDEE;
  left: 0;
}

.content.list li:last-child {
  margin: 0;
}

.content.list .fg-white {
  color: #fff;
}

@media (min-width: 576px) {
  .content .title {
    font-size: 2.5rem;
    padding: 0 0 2rem;
  }
  .content .title-sub {
    font-size: 1.5rem;
  }
  .content p {
    font-size: 1.125rem;
    line-height: 1.85rem;
  }
  .content.list ul .list-title {
    font-size: 1.5rem;
  }
  .content.list li {
    font-size: 1.25rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #092a44;
  transition: background-color 5000s ease-in-out 0s;
}

.desc {
  font: 400 1rem/1.6rem "Open Sans", sans-serif;
  margin-bottom: 1.5625rem;
  color: #595f7c;
}

span.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  width: 100%;
}

.bgblue {
  background-image: url(/assets/images/bg-migration.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.bg-blue {
  background-image: url(/assets/images/gradient-blue.jpg);
}

.bg-white {
  background-color: #fff;
}

.bg-pale {
  background-color: #e1eaf8;
}

@media (min-width: 1200px) {
  .barrel {
    background-repeat: no-repeat !important;
  }
  .barrel-white-bg {
    background-image: url(/assets/images/whitebarrel.png);
    background-position: calc(100vw - 465px) bottom;
  }
  .barrel-pale-bg {
    background: url(/assets/images/palebarrel.png);
    background-position: -50px bottom;
  }
}

.rsb {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img-res-fix:not(.png) {
  width: 100%;
  padding: 0 1rem;
}

.carousel-indicators li {
  width: 14px;
  height: 14px;
  margin: 0 4px;
  background: transparent;
  border: 1px solid #1BDDEE;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.carousel-indicators li.active {
  background-color: #1BDDEE;
}

.gradien-orange {
  background-image: linear-gradient(to right, #ff5910, #ff6c0f, #ff7d13, #ff8c1a, #ff9b24);
  margin-bottom: -2px;
}

.gradien-orange h4 {
  font: 500 1.125rem "Open Sans", sans-serif;
  padding: 1.125rem 0;
  margin-bottom: 0;
  color: #fff;
}

.card-border-none {
  border: 0;
}

.card-9 {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
}

.btn-gradient {
  background: linear-gradient(90deg, #ff5910 0%, #ff9b24 100%);
  position: relative;
  z-index: 5;
  display: inline-table;
}

.btn-gradient:before {
  background-image: linear-gradient(90deg, #ff5910 0%, #ff5910 100%);
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -khtml-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  border-radius: inherit;
  position: absolute;
  z-index: -5;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn-gradient:hover:before {
  opacity: 1;
}

.btn-bold {
  font: 800 1.125rem "Open Sans", sans-serif;
  padding: .65rem 2rem;
  letter-spacing: 1px;
  color: #fff;
  border: 0;
  width: 100%;
  display: block;
}

@media (min-width: 576px) {
  .btn-bold {
    font-size: 1.5rem;
    padding-right: 4rem;
    padding-left: 4rem;
    width: unset;
    display: inline;
  }
}

.btn-bold:hover {
  color: #fff;
}

.radius-60 {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  border-radius: 60px;
}

.color-0 {
  color: #333;
}

.color-1 {
  color: #1BDDEE;
}

.color-2 {
  color: #0890fb;
}

.color-3 {
  color: #006cef;
}

.color-4 {
  color: #092a44;
}

.color-5 {
  color: #051644;
}

.color-6 {
  color: #e1eaf8;
}

.color-7 {
  color: #181d35;
}

.color-8 {
  color: #0c112a;
}

.color-9 {
  color: #595f7c;
}

.color-10 {
  color: #b9bbcd;
}

.color-11 {
  color: #fff;
}

.color-bg-2 {
  background-color: #0890fb;
}

.color-bg-3 {
  background-color: #006cef;
}

.color-bg-5 {
  background-color: #051644;
}

.color-bg-6 {
  background-color: #e1eaf8;
}

.font-s-1 {
  font-size: 1.125rem;
}

.font-s-2 {
  font-size: 1.5rem;
}

.font-s-3 {
  font-size: 2.5rem;
}

.font-s-4 {
  font-size: 1.25rem;
}

.font-s-5 {
  font-size: 1.875rem;
}

.font-s-6 {
  font-size: 1.75rem;
}

.font-s-7 {
  font-size: 1rem;
}

.font-s-8 {
  font-size: 0.9375rem;
}

.font-w-3 {
  font-weight: 300;
}

.font-w-4 {
  font-weight: 400;
}

.font-w-5 {
  font-weight: 500;
}

.font-w-6 {
  font-weight: 600;
}

.font-w-7 {
  font-weight: 700;
}

.font-w-8 {
  font-weight: 800;
}

.font-h-1b {
  line-height: 1.95rem;
}

.font-h-2 {
  line-height: 2rem;
}

.font-open {
  font-family: "Open Sans", sans-serif;
}

.font-rubik {
  font-family: "Rubik", sans-serif;
}

.space-pt-5 {
  padding-top: 5rem;
}

.space-pb-5 {
  padding-bottom: 5rem;
}

.btn {
  display: block;
  text-decoration: none;
}

.btn--n-1 {
  color: #1BDDEE;
}

.btn--h-1:hover {
  color: #1BDDEE;
}

.btn--tu:hover {
  text-decoration: underline;
}

.btn--bg-1 {
  background-color: #006cef;
}

.btn--bg-1:hover {
  background-color: #0890fb;
}

.font-open {
  font-family: "Open Sans", sans-serif;
}

.font-rubik {
  font-family: "Rubik", sans-serif;
}

.banner {
  background-image: url(/assets/images/bg-migration.jpg);
}

.banner .content .title {
  padding: 2.5rem 0 1.125rem;
}

.banner .content .title-sub {
  font-family: "Rubik", sans-serif;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.banner .content.list li {
  margin-bottom: .5rem;
  color: #1bddee;
}

.banner .content--btn-wrapper {
  width: 100%;
  padding-bottom: 3rem;
}

@media (min-width: 576px) {
  .banner {
    min-height: 22.5rem;
    margin-top: 2.5rem;
  }
  .banner .content .title {
    font-size: 2.8125rem;
    padding: 3.125rem 0 0;
  }
  .banner .content .title-sub {
    font-size: 1.875rem;
    margin-bottom: 1.75rem;
  }
  .banner .content--btn-wrapper {
    width: auto;
    display: inline-flex;
  }
}

.banner .section--title {
  margin: 0;
}

@media (min-width: 992px) {
  .banner--domain-search {
    background-image: url(/assets/images/domain-banner-bg.jpg);
  }
  .banner--web-hosting {
    background-image: url(/assets/images/web-hosting-bg.jpg);
  }
  .banner--ssl-certificates {
    background-image: url(/assets/images/ssl-certificates-bg.jpg);
  }
  .banner--web-transfer {
    background-image: url(/assets/images/web-hosting-transfer-bg.jpg);
  }
  .banner--wow-private-server {
    background-image: url(/assets/images/wow-private-server-hosting-bg.jpg);
  }
  .banner--about-us {
    background-image: url(/assets/images/about-banner-bg.jpg);
  }
  .banner--vps-hosting {
    background-image: url(/assets/images/virtual-private-servers-bg.jpg);
  }
}

.banner--domain-search .section--title {
  font-size: 1.5rem;
  padding-top: 2.75rem;
  padding-bottom: 1.75rem;
  font-weight: 600;
  color: #fff;
}

@media (min-width: 576px) {
  .banner--domain-search .section--title {
    padding: 4.5rem 0 2.25rem;
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .banner--domain-search .section--title {
    font-size: 2.1875rem;
  }
}

.banner--domain-search .section--title span {
  color: #1BDDEE;
}

.with-ribbon {
  position: relative;
  overflow: hidden;
}

.with-ribbon .ribbon {
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 1;
  overflow: hidden;
  width: 135px;
  height: 135px;
  text-align: right;
}

.with-ribbon .ribbon span {
  font: 600 0.75rem/1 "Open Sans", sans-serif;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 170px;
  display: block;
  background: #181d35;
  box-shadow: 0 3px 10px -5px #333;
  position: absolute;
  top: 27px;
  right: -38px;
}

.with-ribbon .ribbon span:before, .with-ribbon .ribbon span:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 100%;
  border: 0;
}

.with-ribbon .ribbon span:before {
  left: 0px;
}

.with-ribbon .ribbon span:after {
  right: 0px;
}

@media (min-width: 576px) {
  .with-ribbon .ribbon span:before {
    border-left: 3px solid #000;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #000;
  }
  .with-ribbon .ribbon span:after {
    border-left: 3px solid transparent;
    border-right: 3px solid #000;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #000;
  }
}

.packages {
  background-color: #e1eaf8;
  padding-top: 1.25rem;
}

.packages .table thead th {
  border-top: 0;
}

.packages .table [class^="col-"], .packages .table [class^="col"] {
  flex-shrink: 1;
}

.packages .package.md {
  padding-bottom: 1.5rem;
}

.packages .package.md-package {
  padding-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .packages .package {
    padding-bottom: 19.35rem;
  }
  .packages .package.md {
    padding-bottom: 3.5rem;
  }
  .packages .package.md-package {
    padding-bottom: 19.35rem;
  }
}

.packages .package a {
  text-decoration: none;
}

@media (min-width: 576px) {
  .packages .package .plan {
    padding-bottom: 1rem;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    -khtml-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
  }
  .packages .package .plan:hover {
    transform: scale(1.025);
  }
}

.packages .package .plan--domain:hover {
  transform: none;
}

.packages .package .plan--domain .plan--wrapper {
  padding: 2.5rem;
}

.packages .package .plan--domain .plan--wrapper .jumbotron {
  overflow: hidden;
  position: relative;
  padding-bottom: 8rem !important;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
  background: #000066;
  background: -moz-linear-gradient(90deg, #000066 0%, #003399 100%);
  background: -webkit-linear-gradient(90deg, #000066 0%, #003399 100%);
  background: linear-gradient(90deg, #000066 0%, #003399 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000066",endColorstr="#003399",GradientType=1);
}

.packages .package .plan--domain .plan--wrapper .jumbotron .searchbar {
  z-index: 1;
}

.packages .package .plan--domain .plan--wrapper .jumbotron:after {
  content: "";
  background-image: url(/assets/images/domain-banner-icon.svg);
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  position: absolute;
  top: 0;
  right: -2.8125rem;
}

.packages .package .plan--wrapper {
  padding: 0.9375rem 1.875rem;
  background-color: #fff;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.packages .package .plan--wrapper a.btn, .packages .package .plan--specs li {
  font: 400 1rem "Open Sans", sans-serif;
}

.packages .package .plan--wrapper a.btn {
  padding: .75rem 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
}

.packages .package .plan--specs {
  margin-top: 1.25rem;
}

.packages .package .plan--specs li {
  color: #595f7c;
  border-top: 1px solid #e1eaf8;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.packages .package .plan--specs li b {
  font-weight: 800;
}

.packages .package .plan--specs li:last-child {
  padding-bottom: 0;
}

.packages .package .plan--specs-icon li {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.packages .package .plan--specs-icon li b, .packages .package .plan--specs-icon li strong, .packages .package .plan--specs-icon li span {
  margin-right: 4px;
}

.packages .package .plan--specs-icon li:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1rem;
  margin-right: 4px;
  color: #2FDDEA;
  width: 23px;
  content: "";
}

.packages .package .plan--specs-icon li.cpu:before {
  background-image: url(/assets/images/cpu-cores-icon.svg);
  background-size: 19px 19px;
  background-repeat: no-repeat;
  display: block;
  width: 19px;
  height: 19px;
  margin-right: 8px;
}

.packages .package .plan--specs-icon li.ram:before {
  content: "\f538";
}

.packages .package .plan--specs-icon li.hdd:before {
  content: "\f0a0";
}

.packages .package .plan--specs-icon li.person:before {
  content: "\f007";
}

.packages .package .plan--specs-icon li.exc:before {
  content: "\f362";
}

.packages .package .plan--specs-icon li.shield:before {
  content: "\f3ed";
}

.packages .package .plan--specs-icon li.globe:before {
  content: "\f0ac";
}

.packages .package .plan--specs-icon li.ups:before {
  content: "\f35b";
}

.packages .package .plan--specs-icon li.wow {
  display: inline-block;
  padding-left: 27px;
  position: relative;
}

.packages .package .plan--specs-icon li.wow b, .packages .package .plan--specs-icon li.wow strong, .packages .package .plan--specs-icon li.wow span {
  margin: unset;
}

.packages .package .plan--specs-icon li.wow i {
  position: absolute;
  font-weight: 600;
  top: .75rem;
  left: 0;
}

.packages .package .plan--specs-icon li.wow:before {
  content: none;
}

.packages .package .plan--specs-icon li.inline {
  padding-left: 27px;
  display: block;
}

.packages .package .plan--specs-icon li.inline:before {
  margin-left: -1.65rem;
  margin-right: .65rem;
}

.packages .package .plan--specs-icon li.tip span {
  border-bottom: 1px dashed #515771;
}

.packages .package .plan h6 {
  margin-bottom: 1rem;
}

.packages .package .plan p,
.packages .package .plan strong {
  font: 400 1rem/1.5rem "Open Sans", sans-serif;
  margin-bottom: 1.5625rem;
  color: #595f7c;
}

.packages .package .plan p.price,
.packages .package .plan strong.price {
  font: 700 2.5rem "Rubik", sans-serif;
  margin-bottom: 1rem;
  color: #0890fb;
}

.packages .package .plan p.price span:before, .packages .package .plan p.price span.before,
.packages .package .plan strong.price span:before,
.packages .package .plan strong.price span.before {
  font-size: 1.25rem;
  position: relative;
  top: -.85rem;
  font-weight: 600;
}

.packages .package .plan p.price span:before,
.packages .package .plan strong.price span:before {
  content: "$";
}

.packages .package .plan p.price span.before,
.packages .package .plan strong.price span.before {
  text-decoration: line-through;
}

.packages .package .plan p.price span.before:before,
.packages .package .plan strong.price span.before:before {
  content: "";
}

@media (max-width: 374.98px) {
  .packages .package .plan p.price span.before,
  .packages .package .plan strong.price span.before {
    position: static;
  }
}

.packages .package .plan p.price small,
.packages .package .plan strong.price small {
  font-size: 1rem;
  font-weight: 500;
}

.packages .package .plan span.icon {
  height: 70px;
  margin: .25rem 0 .6rem;
}

.packages .package .plan span.icon--basic {
  background-image: url(/assets/images/web-hosting-icon.svg);
}

.packages .package .plan span.icon--wow {
  background-image: url(/assets/images/wow-hosting-icon.svg);
}

.packages .package .plan span.icon--vps {
  background-image: url(/assets/images/vps-hosting-icon.svg);
}

.packages .package .plan span.icon--dedicated {
  background-image: url(/assets/images/dedicated-hosting-icon.svg);
}

.packages .package .plan span.icon--starter {
  background-image: url(/assets/images/web-starter-icon.svg);
}

.packages .package .plan span.icon--standard {
  background-image: url(/assets/images/web-standard-icon.svg);
}

.packages .package .plan span.icon--business {
  background-image: url(/assets/images/web-business-icon.svg);
}

.packages .package .plan span.icon--business-pro {
  background-image: url(/assets/images/web-business-pro-icon.svg);
}

.packages .package .plan span.icon--ssl {
  background-image: url(/assets/images/standard-ssl.svg);
}

.packages .package .plan span.icon--pre-ssl {
  background-image: url(/assets/images/premium-ssl.svg);
}

.packages .package .plan span.icon--wow-basic {
  background-image: url(/assets/images/wow-hosting-icon.svg);
}

.packages .package .plan span.icon--wow-pro {
  background-image: url(/assets/images/wow-pro.svg);
}

.packages .package .plan span.icon--wow-mega {
  background-image: url(/assets/images/wow-mega.svg);
}

.packages .package .plan span.icon--wow-ultra {
  background-image: url(/assets/images/wow-ultra.svg);
}

.packages .package .plan span.icon--vps-basic {
  background-image: url(/assets/images/vpsEntry.svg);
}

.packages .package .plan span.icon--vps-pro {
  background-image: url(/assets/images/vpsGO.svg);
}

.packages .package .plan span.icon--vps-supreme {
  background-image: url(/assets/images/vpsUltra.svg);
}

.packages .package .plan span.icon--vps-super {
  background-image: url(/assets/images/vpsUltra2.svg);
}

.packages .package .plan span.icon--wow-item {
  height: 55px;
}

.packages .package .plan strong {
  font-weight: 800;
  font-style: italic;
  display: inline-block;
  margin-bottom: .5rem;
}

.packages .package.compare .row--content {
  margin-left: -5px;
  margin-right: -5px;
}

.packages .package.compare .row--content [class^=col], .packages .package.compare .row--content [class^=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.packages .package.compare .plan h6 {
  margin-bottom: 0;
}

.packages .package.compare .plan--wrapper a.btn {
  margin-bottom: .25rem;
}

.packages .package.compare .plan--specs {
  margin-left: -1.875rem;
  margin-right: -1.875rem;
}

.packages .package.compare .plan--specs li {
  font-size: 0.9375rem;
  border-top: 1px dashed #e1eaf8;
}

.packages .package.compare .plan--specs li.price {
  font: 700 1.5rem "Rubik", sans-serif;
}

.packages .package.compare .plan--specs li.price span:before {
  content: "$";
  font-size: 0.875rem;
  position: relative;
  top: -.45rem;
}

.packages .package.compare .plan--specs li.price small {
  font-weight: 600;
}

.packages .package.compare .plan--specs li:first-child {
  border-top: 2px solid #e1eaf8;
}

.packages .package.compare .plan--specs li:last-child {
  border-bottom: 1px dashed #e1eaf8;
}

.packages .btn-compare {
  font: 600 1.125rem "Open Sans", sans-serif;
  padding: .7rem 1rem;
}

.packages .btn-compare i {
  margin-left: 3px;
}

.contact-us .btn {
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  border-radius: 60px;
  padding: 1.1875rem 4rem;
  border: 0;
}

.contact-us li, .contact-us span, .contact-us a {
  font-size: 1.5rem;
  font-weight: 800;
  color: #FFF;
}

.contact-us li {
  margin-bottom: 1rem;
}

.contact-us li a {
  line-height: 0.5;
  letter-spacing: 1px;
}

@media (min-width: 576px) {
  .contact-us li a {
    display: inline-table;
  }
}

.contact-us li span {
  color: #1BDDEE;
}

@media (min-width: 576px) {
  .contact-us li {
    margin-right: 1.25rem;
    margin-bottom: 0;
  }
}

.contact-us li:last-child {
  margin-right: 0;
}

.contact-us.basic a {
  padding: 1.1875rem 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.contact-us.basic a, .contact-us.basic span {
  color: #0890fb;
}

.contact-us.basic li {
  color: #092a44;
}

.contact-us.basic li:first-child {
  margin-right: 5px;
}

.table-row-header {
  position: relative;
}

.table-cell {
  padding: 0 !important;
}

.table-cell > div {
  position: relative;
  padding: .75rem 1rem;
  height: 100%;
}

@media (min-width: 576px) {
  .table-cell > div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.table-cell > div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  height: 1px;
  width: 100%;
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: 6px 1px;
  background-image: linear-gradient(to right, #d4d4d4 4px, transparent 4px);
}

.table-cell > div span {
  font: 400 0.875rem "Open Sans", sans-serif;
}

@media (min-width: 992px) {
  .table-cell > div span {
    font-size: 0.9375rem;
  }
}

.table-cell > div span.price {
  font: 700 1.5rem "Rubik", sans-serif;
}

.table-cell > div span.price:before {
  content: "$";
  font-size: 50%;
  font-weight: inherit;
  top: -.5rem;
  position: relative;
}

.table-cell > div span.price small {
  font-size: 1rem;
  font-weight: inherit;
}

.table-cell:not(.cell-name):not(.cell-label) > div {
  background-color: #fff;
  text-align: center;
}

.table-cell.cell-name > div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.table-cell.cell-name span {
  font-weight: 700;
}

.table-cell.cell-name i {
  color: #1bddee;
}

@media (min-width: 576px) {
  .table-cell.cell-name {
    z-index: 38;
  }
  .table-cell.cell-name span {
    padding-left: 5px;
  }
}

.table-cell.action a {
  margin: 5px 0;
  color: #fff;
  display: block;
  padding: .75rem 0;
  font: 700 1rem "Open Sans", sans-serif;
}

@media (min-width: 992px) {
  .table-cell.action a {
    font-size: 1.125rem;
  }
}

.table-cell.empty {
  display: none;
}

@media (min-width: 576px) {
  .table-cell.empty {
    display: table-cell;
  }
  .table-cell.empty > div:after {
    content: none;
  }
}

@media (min-width: 576px) {
  .table-cell {
    padding: 0 5px !important;
    width: 222px;
  }
}

.table-compare-wrapper {
  margin-bottom: -1rem;
  padding-bottom: 1rem;
}

.table-compare-wrapper .floater-header-compare.floated table .cell-header > div {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}

.table-compare-wrapper .floater-header-compare:before {
  background-color: red;
  position: absolute;
  height: .25rem;
  width: 100%;
  top: 0;
}

@media (max-width: 575.98px) {
  .table-compare-wrapper .table-responsive:before {
    height: 95px;
    width: 100%;
    background-color: red;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 36;
  }
}

@media (min-width: 1200px) {
  .table-compare-wrapper .table-responsive {
    overflow-x: hidden;
  }
}

.table-compare .cell-header {
  border-bottom: none;
  text-align: center;
}

.table-compare .cell-header > div {
  -webkit-border-top-left-radius: 9px !important;
  -moz-border-radius-topleft: 9px !important;
  border-top-left-radius: 9px !important;
  -webkit-border-top-right-radius: 9px !important;
  -moz-border-radius-topright: 9px !important;
  border-top-right-radius: 9px !important;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  border-bottom: 2px solid #d5d7de;
}

.table-compare .cell-header > div:after {
  content: none;
}

.table-compare .cell-footer > div {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topright: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 9px;
  -moz-border-radius-bottomleft: 9px;
  border-bottom-left-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-bottomright: 9px;
  border-bottom-right-radius: 9px;
}

@media (max-width: 575.98px) {
  .table-compare .cell-name > div i {
    display: none;
  }
}

.table-compare .cell-arrow {
  position: absolute;
  z-index: 2;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  top: 50%;
}

.table-compare .cell-arrow .arrow {
  color: #b6b8c4;
  position: absolute;
}

.table-compare .cell-arrow .arrow:hover {
  cursor: pointer !important;
  color: #18dded;
}

.table-compare .cell-arrow .arrow.f {
  left: 26px;
}

.table-compare .cell-arrow .arrow.l {
  right: 26px;
}

@media (min-width: 576px) {
  .table-compare .cell-arrow {
    display: none;
  }
}

.table-compare .cell-label > div {
  padding: 1.5rem 0;
}

.table-compare .cell-label > div span {
  font: 700 1.25rem "Rubik", sans-serif;
  color: #092a44;
}

@media (min-width: 576px) {
  .table-compare .cell-label > div span {
    font-size: 1.5rem;
  }
}

.table-compare thead th > div {
  padding: 0;
}

@media (max-width: 575.98px) {
  .table-compare tbody tr td.table-cell {
    background-color: #fff;
  }
  .table-compare tbody tr td.cell-label {
    background-color: transparent;
    text-align: center;
  }
  .table-compare tbody tr td.cell-label > div {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.table-compare tbody tr:first-child td > div:after {
  content: none;
}

.table-compare .plan {
  padding-bottom: 0 !important;
  padding-top: 1.25rem;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -khtml-transition: none !important;
  transition: none !important;
}

.table-compare .plan:hover {
  transform: scale(1) !important;
}

.table-compare .plan h5 {
  margin-bottom: 1rem;
}

.tooltip {
  opacity: 1 !important;
}

.tooltip-table-compare {
  display: none;
}

@media (min-width: 576px) {
  .tooltip-inner {
    background-color: #181d35;
    padding: .6rem .75rem;
    color: #595f7c;
    text-align: left;
    font: 400 0.8125rem "Open Sans", sans-serif;
  }
  .tooltip-inner p {
    margin-bottom: .25rem;
    font-weight: 600;
    font-size: .875rem;
    color: #fff;
  }
  .tooltip-table-compare {
    display: block;
    left: 15px !important;
    top: -5px !important;
  }
  .tooltip-table-compare .tooltip-inner {
    max-width: 215px;
    /* If max-width does not work, try using width instead */
    width: 215px;
  }
  .tooltip-table-compare .arrow {
    left: 18px !important;
  }
  .tooltip-table-compare .arrow:before {
    border-top-color: #181d35;
  }
}

.features {
  padding-bottom: 3rem;
  background-color: #fff;
}

@media (min-width: 1200px) {
  .features {
    padding-bottom: 12.75rem;
  }
}

.features h3.section--title {
  padding-top: 5rem;
}

.features .fig {
  padding: 1.25rem;
  margin-bottom: 1rem;
}

.features .fig span.icon {
  height: 62px;
}

.features .fig span.icon--fig1 {
  background-image: url("/assets/images/support/24-7-365-Support.svg");
}

.features .fig span.icon--fig2 {
  background-image: url("/assets/images/support/45-Day-Guarantee.svg");
}

.features .fig span.icon--fig3 {
  background-image: url("/assets/images/support/Uptime-Guarantee.svg");
}

.features .fig span.icon--fig4 {
  background-image: url("/assets/images/support/Fast-Load-Time.svg");
}

.features .fig span.icon--fig5 {
  background-image: url("/assets/images/support/Included-Apps.svg");
}

.features .fig span.icon--fig6 {
  background-image: url("/assets/images/support/Flexibility.svg");
}

.features .fig span.icon--fig7 {
  background-image: url("/assets/images/support/DDoS-Protection.svg");
}

.features .fig span.icon--fig8 {
  background-image: url("/assets/images/support/Real-Cloud.svg");
}

.features .fig span.icon--fig9 {
  background-image: url("/assets/images/support/Powerful-Tools.svg");
}

.features .fig span.icon--fig10 {
  background-image: url("/assets/images/support/fet-fast-validation.svg");
}

.features .fig span.icon--fig11 {
  background-image: url("/assets/images/support/fet-encryption.svg");
}

.features .fig span.icon--fig12 {
  background-image: url("/assets/images/support/fet-site-seal.svg");
}

.features .fig span.icon--fig13 {
  background-image: url("/assets/images/support/fet-trusted-by-all-browsers.svg");
}

.features .fig span.icon--fig14 {
  background-image: url("/assets/images/support/fet-45-day-guarantee.svg");
}

.features .fig span.icon--fig15 {
  background-image: url("/assets/images/support/fet-expert-assistance.svg");
}

.features .fig span.icon--fig16 {
  background-image: url("/assets/images/support/fet-scalability.svg");
}

.features .fig span.icon--fig17 {
  background-image: url("/assets/images/support/fet-powerful-tools.svg");
}

.features .fig h6 {
  margin-bottom: 0;
  padding: 1.25rem 0;
}

.features .fig .desc {
  border-top: 1px solid #e1eaf8;
  padding-top: .75rem;
  margin: 0 -.5rem;
}

@media (min-width: 1200px) {
  .container-migration {
    margin-top: -16.5625rem;
  }
  .container-migration.bgblue {
    background: transparent;
  }
  .container-migration.bgblue:before {
    background: url(/assets/images/bg-migration.jpg) top right no-repeat;
    width: calc( 50% + 553px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border-radius: 0 300px 300px 0;
  }
}

.container-migration .migration-info {
  padding: 3rem 0 2rem;
}

.container-migration .migration-info h4 {
  margin: 0;
  padding-top: 0;
  padding-bottom: 1rem;
}

.container-migration .migration-info p, .container-migration .migration-info span {
  color: #b9bbcd;
  font-family: "Open Sans", sans-serif;
}

.container-migration .migration-info span {
  font-weight: 700;
}

.container-migration .migration-info p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 2rem;
}

.container-migration .migration-info .title-sub {
  font-size: 1.125rem;
}

@media (min-width: 576px) {
  .container-migration .migration-info {
    padding: 5rem 0;
  }
  .container-migration .migration-info h4 {
    padding-bottom: 2rem;
  }
  .container-migration .migration-info .title-sub {
    font-size: 1.5rem;
  }
  .container-migration .migration-info p {
    font-size: 1.125rem;
  }
}

@media (max-width: 991.98px) {
  .container-migration .migration-image {
    padding-top: 5rem;
    margin-bottom: -2rem;
  }
}

@media (min-width: 992px) {
  .container-migration .migration-image {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .container-fluid-testimony {
    background-color: #e1eaf8;
    padding-bottom: 5rem;
  }
}

@media (min-width: 1200px) {
  .container-testimonials.bgblue {
    background: transparent;
    margin-bottom: 0;
    margin-top: -10.625rem;
  }
  .container-testimonials.bgblue:before {
    background: url(/assets/images/bg-migration.jpg) 0 0 no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    content: "";
    right: 0;
    top: 0;
    width: calc(50% + 555px);
    border-radius: 300px 0 0 300px;
  }
}

.container-testimonials .carousel {
  padding: 3rem 0 5rem 0;
}

.container-testimonials .carousel .section--title {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: #fff;
  padding-top: 0;
  padding-bottom: 1.5rem;
}

.container-testimonials .carousel .blockquote {
  margin: 0;
}

.container-testimonials .carousel .blockquote p {
  font: 800 1.25rem "Open Sans", sans-serif;
  color: #dedfe9;
  margin-bottom: 0;
  padding-left: 4rem;
  background-image: url(/assets/images/quotes.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 40px;
}

.container-testimonials .carousel .blockquote p cite {
  font-weight: 300;
  font-style: italic;
}

@media (min-width: 992px) {
  .container-testimonials .carousel {
    padding: 5rem 5rem 7rem 5rem;
  }
}

@media (min-width: 576px) {
  .container-testimonials .carousel .section--title {
    font-size: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .container-testimonials .carousel .blockquote p {
    font-size: 1.5rem;
    padding-left: 6.25rem;
    background-size: 65px;
  }
  .container-testimonials .carousel .blockquote span {
    font-size: 1.125rem;
    padding-top: .75rem;
    display: block;
  }
}

.container-testimonials .carousel .carousel-indicators {
  bottom: 20px;
}

@media (min-width: 768px) {
  .container-testimonials .carousel .carousel-indicators {
    bottom: 30px;
  }
}

.contact-point {
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}

.contact-point .contact-24-7 h4 {
  font: 700 1.875rem "Rubik", sans-serif;
  padding: 0;
}

.contact-point .contact-24-7 span {
  font: 400 1.125rem "Open Sans", sans-serif;
}

.contact-point .contact-24-7 h4,
.contact-point .contact-24-7 span {
  color: #fff;
}

.contact-point .contact-24-7 > div {
  padding-top: .65rem;
}

@media (max-width: 1199.98px) {
  .contact-point .contact-24-7 > div {
    padding-top: 0;
    text-align: center;
    margin-bottom: 2.25rem;
  }
}

@media (max-width: 767.98px) {
  .contact-point .contact-24-7 > div {
    margin: 0 -15rem 1.25rem;
  }
  .contact-point .contact-24-7 h4 {
    font-size: 1.5rem;
  }
  .contact-point .contact-24-7 span {
    font-size: 1rem;
  }
}

.contact-point .item {
  font: 400 1rem "Open Sans", sans-serif;
  margin-right: 1.75rem;
  margin-bottom: .75rem;
  color: #fff;
}

.contact-point .item span {
  display: none;
}

.contact-point .item strong {
  font: 700 1.5rem "Rubik", sans-serif;
}

.contact-point .item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.contact-point .item:before {
  border: 10px solid rgba(255, 255, 255, 0.25);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
  display: inline-block;
  content: "";
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 2rem;
  margin-right: 0;
}

@media (min-width: 576px) {
  .contact-point .item span {
    display: inline-block;
    padding-top: 1rem;
  }
  .contact-point .item:before {
    margin-right: .75rem;
  }
}

.contact-point .item--mail:before {
  background-image: url(/assets/images/point-send-email.svg);
  background-size: 55% auto;
}

.contact-point .item--call:before {
  background-image: url(/assets/images/point-call-us.svg);
  background-size: 40% auto;
}

.contact-point .item--chat:before {
  background-image: url(/assets/images/point-chat-with-us.svg);
  background-size: 55% auto;
}

.links-and-info {
  background-color: #181C2F;
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .links-and-info {
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;
  }
}

@media (max-width: 575.98px) {
  .links-and-info {
    background-color: #0c112a;
  }
}

.links-and-info:before {
  background: #0c112a;
  position: absolute;
  height: 100%;
  content: "";
  right: 0;
  top: 0;
}

@media (min-width: 576px) {
  .links-and-info:before {
    width: calc( 50% - 135px);
  }
}

@media (max-width: 991.98px) {
  .links-and-info:before {
    width: calc( 50% - 35px);
  }
}

.links-and-info > .container:first-child .row .internal-links:last-child span.hostbarrel {
  background: url(/assets/images/hostbarrel2.svg) no-repeat right center/contain;
  display: inline-block;
  margin-bottom: 3.5rem;
  width: 100%;
  height: 70px;
}

.links-and-info > .container:first-child .row .internal-links:last-child h4, .links-and-info > .container:first-child .row .internal-links:last-child h5 {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  white-space: nowrap;
}

.links-and-info > .container:first-child .row .internal-links:last-child h4 {
  color: #fff;
  font-size: 2.5rem;
}

.links-and-info > .container:first-child .row .internal-links:last-child h5 {
  color: #1BDDEE;
  font-size: 1.5rem;
}

.links-and-info .internal-links ul {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 2rem;
}

.links-and-info .internal-links ul strong {
  text-transform: uppercase;
  font-size: 1rem;
  color: #fff;
  margin-bottom: .5rem;
  display: block;
}

.links-and-info .internal-links ul li {
  margin-bottom: .35rem;
}

.links-and-info .internal-links ul li:last-child {
  margin-bottom: 0;
}

.links-and-info .internal-links ul li a {
  text-decoration: none;
  font-size: .875rem;
  color: #595f7c;
}

.links-and-info .internal-links ul li a:hover {
  color: #fff;
}

.links-and-info .internal-links ul.payment-gateway {
  margin-bottom: 0;
}

.links-and-info .internal-links ul.payment-gateway li {
  margin-bottom: 0;
}

.links-and-info .internal-links ul.payment-gateway li > i {
  color: #515771;
  font-size: 2.5rem;
}

.links-and-info .internal-links ul.social-media {
  margin-bottom: 0;
}

.links-and-info .internal-links ul.social-media > li {
  margin-bottom: 0;
}

.links-and-info .internal-links ul.social-media > li a {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #181C2A;
  line-height: 3.375rem;
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  height: 3.375rem;
  width: 3.375rem;
  color: #fff;
}

.site-managed p, .site-managed a {
  color: #595f7c;
}

.site-managed p {
  font: 400 0.8125rem "Open Sans", sans-serif;
  margin: 2rem 0;
}

.site-managed p a {
  text-decoration: none;
}

.site-managed p a:hover {
  text-decoration: underline;
}

.site-managed .compliance {
  padding-bottom: 2.5rem;
}

.site-managed .compliance span.man {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1rem;
}

.site-managed .compliance span.man--secure {
  background-image: url(/assets/images/man-secure_website.svg);
  width: 134px;
  height: 50px;
  margin-right: .5rem;
}

.site-managed .compliance span.man--paypal {
  background-image: url(/assets/images/man-paypal-logo.svg);
  width: 180px;
  height: 50px;
}

.site-managed .compliance span.man--pci {
  background-image: url(/assets/images/man-pci_dss_compliant.svg);
  width: 120px;
  height: 50px;
}

.site-managed .compliance span.man:last-child {
  margin-right: 0;
}

@media (max-width: 575.98px) {
  .site-managed .managed p {
    margin-bottom: 1.25rem;
  }
  .site-managed .managed p span {
    display: inline-block;
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff !important;
  border-bottom: 1px solid #e1eaf8;
}

.navbar .mob.account, .navbar .mob.cart {
  display: block;
  color: #2FDDEA;
}

@media (min-width: 576px) {
  .navbar .mob.account, .navbar .mob.cart {
    display: none;
  }
}

.navbar-toggler {
  position: relative;
  top: 5px;
}

@media (min-width: 576px) {
  .navbar-toggler {
    top: 2px;
  }
}

.navbar-toggler:focus, .navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #092a44;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1), .navbar-toggler span:nth-child(3) {
  transition: transform .35s ease-in-out;
}

@media (max-width: 575.98px) {
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: relative;
    top: 17px;
    transform: rotate(135deg);
    opacity: 0.9;
  }
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: relative;
    top: -6px;
    transform: rotate(-135deg);
    opacity: 0.9;
  }
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

@media (min-width: 576px) {
  .navbar-brand {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
  }
}

.navbar-brand .brand {
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .navbar-brand .brand {
    width: auto;
    padding-right: 0;
  }
}

.navbar-brand .brand img {
  height: 42px;
  margin-top: -5px;
}

@media (min-width: 576px) {
  .navbar-brand .brand img {
    height: 70px;
    margin-top: 0;
  }
}

@media (max-width: 575.98px) {
  .navbar-toggler:not(:disabled):not(.disabled) {
    padding: 0;
    border: 0;
  }
}

.navbar .nav-item {
  margin-left: .5rem;
  margin-right: .5rem;
}

.navbar .nav-item > a.nav-link:hover {
  color: #00DEED !important;
}

@media (min-width: 1200px) {
  .navbar .nav-item:first-child {
    margin-left: 0;
  }
  .navbar .nav-item:last-child {
    margin-right: 0;
  }
}

.navbar-nav-mega > li {
  border-bottom: 3px solid transparent;
}

.navbar-nav-mega > li > a {
  font: 700 1.125rem "Open Sans", sans-serif;
  color: #092a44 !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -khtml-transition: 0.3s;
  transition: 0.3s;
}

.navbar-nav-mega > li > a:after {
  content: none;
}

@media (min-width: 1200px) {
  .navbar-nav-mega > li > a {
    line-height: 86px;
    height: 86px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navbar-nav-mega > li.dropdown > a {
  position: relative;
}

.navbar-nav-mega > li.dropdown > a span:before {
  content: "";
  padding: .25rem;
  /* Arrow size */
  --box-shadow-color:#818EAE;
  box-shadow: 1px -1px 0 1px var(--box-shadow-color) inset;
  border: solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -khtml-transition: 0.3s;
  transition: 0.3s;
  transform: rotate(45deg);
  position: absolute;
  top: 1.15rem;
  right: 2.5rem;
}

@media (min-width: 1200px) {
  .navbar-nav-mega > li.dropdown > a span:before {
    top: 2.35rem;
    right: -1px;
  }
}

@media (min-width: 1200px) {
  .navbar-nav-mega > li.dropdown > a {
    padding-right: 1rem !important;
  }
  .navbar-nav-mega > li.dropdown > a:hover span:before {
    transform: rotate(-45deg);
    top: 2.25rem;
  }
}

.navbar-nav-mega > li.dropdown.show > a span:before {
  --box-shadow-color:#00DEED;
}

@media (max-width: 1199.98px) {
  .navbar-nav-mega > li.dropdown.show > a span:before {
    transform: rotate(-45deg);
    right: 2.75rem;
    top: 1rem;
  }
}

@media (min-width: 1200px) {
  .navbar-nav-mega > li.dropdown.show {
    border-bottom-color: #00DEED;
  }
}

.navbar .mob-navbar {
  display: none;
}

@media (max-width: 575.98px) {
  .navbar .mob-navbar {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
  }
  .navbar .mob-item {
    flex-basis: 100%;
    align-self: center;
    text-align: center;
    line-height: 70px;
    height: 70px;
  }
}

@media (max-width: 1199.98px) {
  .navbar-collapse {
    border-top: 1px solid #e1eaf8;
    margin: 0 -45px 0 -45px;
  }
  .navbar-collapse .navbar-nav .nav-item {
    border-bottom: 1px dashed #e1eaf8;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child {
    border-bottom: none;
  }
  .navbar-collapse .navbar-nav .nav-item > a {
    padding: 12px 2.75rem;
  }
  .navbar-collapse .navbar-nav .nav-item > a:after {
    right: 65px;
  }
  .navbar-collapse .navbar-nav .nav-item .dropdown-menu-mega > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.navbar .support {
  display: block;
  padding: 1.875rem 3.875rem;
  margin-bottom: -5px;
}

@media (min-width: 576px) {
  .navbar .support {
    display: none;
  }
}

.navbar .support h5,
.navbar .support li,
.navbar .support a {
  color: #fff;
}

.navbar .support h5 {
  font: 700 1.125rem "Rubik", sans-serif;
}

.navbar .support h5,
.navbar .support li {
  margin-bottom: .875rem;
}

.navbar .support li {
  font: 400 0.9375rem "Open Sans", sans-serif;
  position: relative;
  padding-left: 1.95rem;
}

.navbar .support li:last-child {
  margin-bottom: 0;
}

.navbar .support li:before {
  font-size: 1.3rem !important;
  color: #fff !important;
  position: absolute;
  left: 0;
}

.con--mega {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -khtml-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
}

.con--mega:hover {
  background-color: #e1eaf8;
}

.con--mega-item {
  padding: 12px 2rem;
  margin-left: -3px;
  margin-right: -3px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border-bottom: 1px dashed #fff;
}

.con--mega-item a {
  text-decoration: none;
}

.con--mega-item .item {
  position: relative;
  padding-left: 55px;
  display: flex;
  align-items: center;
}

.con--mega-item .item h4 {
  font: 500 1rem "Rubik", sans-serif;
  margin-bottom: 0;
  color: #051644;
  letter-spacing: -.25px;
}

.con--mega-item .item .desc {
  font-size: 0.8125rem;
  margin-bottom: 0;
  display: none;
}

.con--mega-item .item:before {
  content: "";
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: contain;
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
}

@media (min-width: 576px) {
  .con--mega-item .item:before {
    height: 48px;
  }
}

@media (min-width: 1200px) {
  .con--mega-item .item:before {
    height: 60px;
  }
}

.con--mega-item .item-hosting-web:before {
  background-image: url(/assets/images/mega-icon-bg-hosting.svg);
}

.con--mega-item .item-hosting-transfer:before {
  background-image: url(/assets/images/mega-icon-bg-hosting-transfer.svg);
}

.con--mega-item .item-hosting-ssl:before {
  background-image: url(/assets/images/mega-icon-bg-ssl.svg);
}

.con--mega-item .item-domain-name:before {
  background-image: url(/assets/images/mega-icon-bg-name.svg);
}

.con--mega-item .item-domain-transfer:before {
  background-image: url(/assets/images/mega-icon-bg-transfer.svg);
}

.con--mega-item .item-domain-name:before {
  background-image: url(/assets/images/mega-icon-bg-name.svg);
}

.con--mega-item .item-domain-transfer:before {
  background-image: url(/assets/images/mega-icon-bg-transfer.svg);
}

.con--mega-item .item-help-knowledge:before {
  background-image: url(/assets/images/mega-icon-bg-kb.svg);
}

.con--mega-item .item-help-contact:before {
  background-image: url(/assets/images/mega-icon-bg-contact.svg);
}

.con--mega-item .item-server-vps:before {
  background-image: url(/assets/images/vps-hosting-icon.svg);
}

.con--mega-item .item-server-wow:before {
  background-image: url(/assets/images/wow-hosting-icon.svg);
}

.con--mega-item .item-server-dedicated:before {
  background-image: url(/assets/images/dedicated-hosting-icon.svg);
}

@media (min-width: 576px) {
  .con--mega-item .item {
    padding-left: 85px;
    display: block;
  }
  .con--mega-item .item .desc {
    display: block;
  }
}

@media (min-width: 1200px) {
  .con--mega-item .item {
    padding-left: 100px;
  }
  .con--mega-item .item .desc {
    font-size: 0.9375rem;
  }
  .con--mega-item .item h4 {
    font-size: 1.25rem;
    margin-bottom: .6rem;
  }
}

@media (min-width: 1200px) {
  .con--mega-item {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    border-radius: 9px;
    padding: 1.25rem;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .con--mega {
    background-color: #fff;
  }
}

.dropdown-mega {
  position: static;
}

.dropdown-menu {
  margin-top: 1.6rem !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.dropdown-menu-mega {
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0 !important;
  -webkit-transition: 0.03s;
  -moz-transition: 0.03s;
  -o-transition: 0.03s;
  -khtml-transition: 0.03s;
  transition: 0.03s;
  border: 0;
  background-color: #e1eaf9;
}

@media (min-width: 1200px) {
  .dropdown-menu-mega {
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-left: none;
    border-right: none;
  }
}

.row--mega {
  background-color: transparent;
}

.row--mega [class^=col]:last-child .con--mega-item,
.row--mega [class^=col-]:last-child .con--mega-item {
  border-bottom: 0;
}

@media (min-width: 1200px) {
  .row--mega [class^=col],
  .row--mega [class^=col-] {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
}

.info ul {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.info a,
.info li {
  font: 400 0.9375rem "Open Sans", sans-serif;
  color: #092a44;
}

.info a {
  text-decoration: none;
}

.info li {
  margin-right: 1.75rem !important;
}

.info li:last-child {
  margin-right: 0 !important;
}

.info li.info-phone:before, .info li.info-email:before, .info li.info-account:before, .info li.info-cart:before {
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
  font-weight: 900;
  font-size: 1rem;
  color: #1BDDEE;
}

.info li.info-phone:before {
  content: "\f590";
}

.info li.info-email:before {
  content: "\f0e0";
}

.info li.info-account:before {
  content: "\f007";
}

.info li.info-cart:before {
  content: "\f07a";
}

.fa-layers {
  position: relative;
}

.fa-layers-counter {
  position: absolute;
  font-size: 9px;
  padding: 2px 3px;
  left: 10px;
  top: -10px;
  color: #fff;
  line-height: normal;
  border: 2px solid #fff;
  background-color: #ff2700;
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  border-radius: 90px;
}

#domain {
  overflow: hidden;
  background-color: #fff;
  border: 9px solid #050c36;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

#domain input, #domain select, #domain button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  font: 500 1rem "Rubik", sans-serif;
  border: 0;
}

@media (min-width: 576px) {
  #domain input, #domain select, #domain button {
    font-size: 1.125rem;
  }
}

#domain select {
  padding-right: 2.25rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  color: #092a44;
}

#domain select option {
  font-weight: 400;
  font-size: 16px;
}

#domain .input-group-append.send button {
  background-image: linear-gradient(to right, #ff5811, #fd6b10, #fb7b14, #f9891c, #f79726);
  color: #fff;
  padding-right: .75rem;
  padding-left: 1rem;
}

#domain .input-group-append.send button span {
  display: none;
}

@media (min-width: 576px) {
  #domain .input-group-append.send button {
    padding: .6rem 3rem;
  }
  #domain .input-group-append.send button span {
    display: inline;
  }
}

#domain .input-group-append.send button:before {
  font-family: "Font Awesome 5 Free";
  margin-right: 8px;
  font-weight: 900;
  content: '\f002';
}

#domain .input-group-append.www button {
  padding: .6rem 0 .6rem 1.25rem;
  background-color: #fff;
  color: #092a44;
  opacity: 1;
}

#domain-ext .tlds li a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: .5rem 1.5rem;
  text-decoration: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

#domain-ext .tlds li.popular a {
  background-color: #33cc66;
}

#domain-ext .tlds li.gtld a {
  background-color: #666;
}

.domain-table-ext {
  margin-bottom: 0;
}

.domain-table-ext thead tr th {
  font-size: 1.5rem;
  border-bottom-width: 4px;
  border-bottom-color: #0890fb;
}

.domain-table-ext th, .domain-table-ext td {
  font-weight: 700;
  text-align: center;
}

.domain-table-ext tbody tr td {
  line-height: 1.25;
  border-top-style: dashed;
}

.domain-table-ext tbody tr td span {
  display: block;
}

.domain-table-ext tbody tr td small {
  font-weight: 500;
  font-size: 90%;
}

.domain-table-ext tbody tr td:first-child {
  font-size: 1.5rem;
}

.domain-table-ext tbody tr:last-child td {
  padding-top: 3.5rem;
}

.domain-table-ext.result tbody tr td {
  padding-left: 20px;
  padding-right: 20px;
}

.domain-table-ext.result tbody tr td:nth-child(1), .domain-table-ext.result tbody tr td:nth-child(2) {
  font-size: 1.5rem;
}

.domain-table-ext.result tbody tr td:nth-child(1) {
  font-weight: 400;
}

.domain-table-ext.result tbody tr td:nth-child(2) {
  font-weight: 800;
}

.domain-table-ext.result tbody tr td:nth-child(2) small {
  font-size: 80%;
}

.domain-table-ext.result tbody tr td:nth-child(2), .domain-table-ext.result tbody tr td:nth-child(3) {
  width: 20% !important;
}

.tld-options {
  margin: 1rem 0 3rem;
}

@media (min-width: 576px) {
  .tld-options {
    margin-top: 1.75rem;
  }
}

.tld-options li {
  border-right: 1px solid #0965c7;
  padding-right: 1rem;
  margin-right: 1rem;
  font: 700 1rem "Rubik", sans-serif;
  margin-right: 1rem !important;
  color: #fff;
}

@media (min-width: 576px) {
  .tld-options li {
    font-size: 1.25rem;
  }
}

.tld-options li:last-child {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
  margin-right: 0 !important;
}

.tld-options li span {
  color: #1BDDEE;
  font-weight: 300;
  margin-left: 7px;
}

.domain-search {
  margin-top: -13rem;
}

.card-domain {
  position: relative;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  color: #b9bbcd;
}

.card-domain .card-body {
  padding: 2rem 2.35rem;
  z-index: 1;
}

.card-domain li {
  flex: 1 1 0;
}

.card-domain li a {
  color: #fff;
  font-weight: 700;
  padding-top: .65rem;
  padding-bottom: .65rem;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.card-domain li a:hover {
  color: inherit;
}

.card-domain li span {
  padding-top: 2px;
  padding-bottom: 2px;
}

.card-domain li:first-child {
  padding-right: 10px;
  line-height: normal;
  font-size: 15px;
  margin: 0;
}

.card-domain li:last-child {
  padding-left: 10px;
}

.card-domain.before li:last-child a {
  background-color: #0890fb;
}

.card-domain.after li:last-child a {
  background-color: #0890fb;
}

.card-domain.before:after, .card-domain.after:after {
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.card-domain.before:after {
  background-image: url(/assets/images/domain-cloud-icon.svg);
  width: 343px;
  height: 255px;
  right: -2.5rem;
  opacity: .2;
}

.card-domain.after:after {
  background-image: url(/assets/images/domain-transfer-icon.svg);
  width: 255px;
  height: 255px;
  right: -2.5rem;
}

.slick-packages {
  padding-bottom: 1.25rem;
}

.slick-prev {
  left: -15px;
}

.slick-next {
  right: -15px;
}

.slick-arrow {
  z-index: 1;
}

.slick-arrow:before {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -khtml-transition: 0.3s;
  transition: 0.3s;
  color: #b6b8c4;
}

.slick-arrow:hover:before, .slick-arrow:focus:before {
  color: #18dded;
}

.card-barrel {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
  padding: 0.9375rem 1.875rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.card-barrel h4 {
  font: 700 1.5rem "Rubik", sans-serif;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  text-align: center;
  margin-bottom: 0;
}

.card-barrel .card-body {
  padding: 1rem 0;
  text-align: center;
}

.card-barrel .card-body p {
  font: 400 1rem/1.65rem "Open Sans", sans-serif;
  color: #666;
}

.accordion-item {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e3e3;
  margin-bottom: 1rem;
  overflow: hidden;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-header, .accordion-body {
  padding: .75rem 1.25rem;
  background-color: #fff;
}

.accordion-header a {
  font: 400 1rem "Rubik", sans-serif;
  background-color: transparent;
  text-decoration: none !important;
  padding: .1rem 0;
  text-align: left;
  color: #092a44;
  display: block;
  border: none;
}

.accordion-header a:before {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  font-weight: 600 !important;
  font-size: 1.5rem;
  display: block;
  width: 20px;
}

.accordion-header span {
  position: relative;
  padding-left: 2rem;
}

.accordion-header span:before {
  content: "";
  font-weight: 100;
  margin: 0 1rem;
  position: absolute;
  left: 0;
  height: 100%;
  width: 1px;
  background-color: #e3e3e3;
}

.accordion-body {
  font: 400 0.875rem/1.65 "Open Sans", sans-serif;
  color: #595f7c;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.accordion-body p:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .accordion-header a {
    font-size: 1.25rem;
    padding: .35rem 1rem;
  }
  .accordion-header span {
    padding-left: 2.5rem;
  }
  .accordion-body {
    font-size: 1rem;
    padding: 1.75rem 5.5rem;
  }
}

.active .accordion-header {
  background-color: #0890fb;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -o-transition: 0.1s;
  -khtml-transition: 0.1s;
  transition: 0.1s;
}

.active .accordion-header a {
  color: #fff;
}

.active .accordion-header a:before {
  content: "\f107";
}

.cloud-top svg, .cloud-bot svg {
  margin: 0 -2px;
}

.cloud-top > div, .cloud-bot > div {
  position: relative;
}

.cloud-inner > .container {
  padding: 12rem 0;
}

.cloud-top {
  margin-bottom: -132px;
}

.cloud-top > div {
  background: #e1eaf8;
  margin-bottom: -1px;
}

.cloud-bot {
  margin-top: -132px;
}

.cloud-bot > div {
  margin-top: -1px;
  background: #fff;
}

@media (min-width: 576px) {
  .card--offset-top {
    margin-top: 2.25rem;
  }
}

#domainTabs {
  border: 0;
  margin-bottom: 50px;
}

#domainTabs li {
  margin-bottom: 3px;
}

#domainTabs a {
  border: 0;
}

#domainTabs a.active {
  background-color: transparent;
}

#domainTabs a.active span {
  padding-bottom: .25rem;
  border-bottom: 3px solid #006cef;
}

.table-tld {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
}

.table-tld .table {
  margin-bottom: 0;
}

.table-tld thead {
  background-image: linear-gradient(to right, #000066, #010e73, #011b80, #01278c, #003399);
}

.table-tld thead th {
  border-width: 0;
  color: #fff;
}

.table-tld td,
.table-tld th {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 0;
  text-align: center;
}

.table-tld td {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.table-tld td:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  height: 1px;
  width: 100%;
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: 6px 1px;
  background-image: linear-gradient(to right, #ccd9dd 4px, transparent 4px);
}

.table-tld tbody {
  background-color: #fff;
}

.table-tld tbody tr:last-child td:after {
  content: none;
}
