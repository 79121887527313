.container {
   &-migration {
      @media (min-width: 1200px) {
         margin-top: -16.5625rem;

         &.bgblue {
            background: transparent; // Override

            &:before {
               background: url(/assets/images/bg-migration.jpg) top right no-repeat;
      
               width: calc( 50% + 553px);
               height: 100%;
               position: absolute;
               top: 0;
               left: 0;
               content: "";
               border-radius: 0 300px 300px 0;
            }
         }
      }

      .migration {
         &-info {
            padding: 3rem 0 2rem;

            h4 {
               margin: 0;
               padding-top: 0;
               padding-bottom: 1rem;
            }

            p, span {
               color: $color-10;
               font-family: $font-stack-open;
            }

            span {font-weight: 700}

            p {
               font-size: 1rem;
               font-weight: 400;
               line-height: 2rem;
               margin-bottom: 2rem;
            }   

            .title-sub {
               font-size: 1.125rem;
            }

            @media(min-width: 576px) { 
               padding: 5rem 0; 
               h4 {
                  padding-bottom: 2rem;
               }

                .title-sub {
                  font-size: 1.5rem;
               }
               p {
                  font-size: 1.125rem;
               }
            }
            
         }

         &-image {
            @media (max-width: 991.98px) {
               padding-top: 5rem;
               margin-bottom: -2rem;
            }

            @media (min-width: 992px) {
               height: 100%;
            }
         }
      }
   }
}