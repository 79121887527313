#domain {
  // Reset Element
  input, select, button {
    @include border-radius(0);
    font: 500 1rem $font-stack-rubik;

    @media (min-width:576px) {
      font-size: 1.125rem;
    }

    border: 0;
  }

  // Styling
  overflow: hidden;
  background-color: #fff;
  border: 9px solid #050c36;
  @include border-radius(50px);

  select {
    padding-right: 2.25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    color:$color-4;
    
    option {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .input-group-append {
    &.send {
      button {
        background-image: linear-gradient(to right, #ff5811, #fd6b10, #fb7b14, #f9891c, #f79726);
        color:#fff;

        span {display: none}
        padding-right: .75rem;
        padding-left: 1rem;

        @media (min-width:576px) {
          span {display: inline}
          padding: .6rem 3rem;
        }

        &:before {
          font-family: "Font Awesome 5 Free";
          margin-right: 8px;
          font-weight: 900; 

          content: '\f002';
        }
      }
    }

    &.www {
      button {
        padding: .6rem 0 .6rem 1.25rem;
        background-color: #fff;
        color:$color-4;
        opacity: 1;
      }
    }
  }

  &-ext {
    .tlds {
      li {
        a {
          color:#fff;
          font-size: 14px;
          font-weight: 600;
          padding: .5rem 1.5rem;
          text-decoration: none;
          @include border-radius(30px);
        }

        &.popular {
          a {background-color:#33cc66}
        }
        &.gtld {
          a {background-color: #666;}
        }
      }
    }

    span {}
  }
}

.domain-table {
  &-ext {
    margin-bottom: 0;
    thead {
      tr {
        th {
          font-size: 1.5rem;
          border-bottom-width: 4px;
          border-bottom-color: #0890fb;
        }
      }
    }

    th, td {
      font-weight: 700;
      text-align: center;
    }

    tbody {
      tr {
        td {
          line-height: 1.25;
          border-top-style: dashed;
          
          span {
            display: block;
          }
          small {
            font-weight: 500;
            font-size: 90%;
          }

          &:first-child {
            font-size: 1.5rem;
          }


        }
        &:last-child {
          // background-color: red;
          td {
            padding-top: 3.5rem;
          }
        }
      }
    }


    &.result {
      tbody {
        tr {
          td {
            padding-left: 20px;
            padding-right: 20px;
            &:nth-child(1), &:nth-child(2) {
              font-size: 1.5rem;
            }
            &:nth-child(1) {
              font-weight: 400
            }
            &:nth-child(2) {
              font-weight: 800;
              small {font-size: 80%}
            }
            &:nth-child(2), &:nth-child(3) {
              width: 20%$mgod;
            }
          }
        }
      }
    }
  }
}

@mixin space-dr($border, $space) {
  border-right: $border;
  padding-right: $space;
  margin-right: $space;
}

.tld-options {
  margin: 1rem 0 3rem;

  @media (min-width:576px) {
    margin-top: 1.75rem;
  }

  li {
    @include space-dr(1px solid #0965c7, 1rem);
    font: 700 1rem $font-stack-rubik;
    margin-right: 1rem$mgod;
    color: #fff;

    @media (min-width:576px) {
      font-size: 1.25rem;
    }

    &:last-child {
      @include space-dr(none, 0);
      margin-right: 0$mgod;
    }

    span {
      color: $color-1;
      font-weight: 300;
      margin-left: 7px;
    }
  }
}

.domain {
  &-search {
    margin-top: -13rem;
  }
}
.card {
  &-domain {
    position: relative;
    overflow: hidden;

    .card-body {
      padding: 2rem 2.35rem;
      z-index: 1;
    }
    font-family: $font-stack-open;
    color:#b9bbcd;
    li {
      flex: 1 1 0;

      a {
        color:#fff;
        font-weight: 700;
        padding-top: .65rem;
        padding-bottom: .65rem;
        @include border-radius(30px);
        &:hover {
          color: inherit;
        }
      }

      span {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      &:first-child {
        padding-right: 10px;
        line-height: normal;
        font-size: 15px;
        margin: 0;
      }

      &:last-child {
        padding-left: 10px;
        
      }
    }

    &.before {
      li {
        &:last-child {
          a {
            background-color: #0890fb;
          }
        }
      }
    }
    &.after {
      li {
        &:last-child {
          a {
            background-color: #0890fb;
          }
        }
      }
    }

    &.before, &.after {
      &:after {
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        // width: 370px;
        // height: 370px;
        top: 0;
      }
    }

    &.before {
      &:after {
        background-image: url(/assets/images/domain-cloud-icon.svg);
        width:343px;
        height:255px;
        right: -2.5rem;
        opacity: .2;
      }
    }
    &.after {
      &:after {
        background-image: url(/assets/images/domain-transfer-icon.svg);
        width:255px;
        height:255px;
        right: -2.5rem;
        // opacity: 1;
      }
    }



  }
}  