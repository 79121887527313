.slick {
   &-packages {
      padding-bottom: 1.25rem;
      @media(min-width: 576px) {
         // padding-bottom: 0;
      }
   }

   &-prev {left: -15px;}
   &-next {right: -15px;}
   &-arrow {
      z-index: 1;
      // height: 30px;
      // width: 30px;

      &:before {
         // font-size: 30px;
         @include transition(0.3s);
         color:#b6b8c4;
      }
      &:hover, &:focus {
         &:before {
            color:#18dded;
         }
      }
   }
}