* {
   -webkit-overflow-scrolling: touch;
 }
@import 'variables';
@import 'modules';
@import 'global';

@import 'sections/wrapper';
@import 'components/wrapper';
@import 'pages/wrapper';
