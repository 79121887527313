.card {
   &-barrel {
      @include border-radius(9px);
        padding: 0.9375rem 1.875rem;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);

      h4 {
         font: 700 1.5rem $font-stack-rubik;
         border-bottom: 1px solid #eee;
         padding-bottom: 1rem;
         text-align: center;
         margin-bottom: 0;
      }

      .card-body {
         padding:1rem 0;
         text-align: center;
         p {
            font: 400 1rem/1.65rem $font-stack-open;
            color:#666;
         }
      }
   }
}