// Font Family
$font-stack-helvetica:  Helvetica, sans-serif;
$font-stack-open: 'Open Sans', sans-serif;
$font-stack-rubik:  'Rubik', sans-serif;
$font-stack-awesome:  "Font Awesome 5 Free";

$dir_support: '/assets/images/support';

$primary-color: #333;

$color-0: #333;
$color-1: #1BDDEE;
$color-2: #0890fb;
$color-3: #006cef;
$color-4: #092a44;
$color-5: #051644;

$color-6: #e1eaf8;

$color-7: #181d35;
$color-8: #0c112a;
$color-9: #595f7c;
$color-10: #b9bbcd;
$color-11: #fff;

$mgod: !important;