.accordion {
   &-item {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
      border: 1px solid #e3e3e3;
      margin-bottom: 1rem;

      overflow: hidden;

      @include border-radius(5px$mgod);
   
      &:last-child {
         margin-bottom: 0;
      }

      
   }

   &-header, &-body {
      padding: .75rem 1.25rem;
      background-color: #fff;
   }

   &-header {
      a {
         font: 400 1rem $font-stack-rubik;
         background-color: transparent;
         text-decoration: none$mgod;
         padding: .1rem 0;
         text-align: left;
         color:#092a44;
         display: block;
         border: none;

         // position: relative;

         &:before {
            font-family: $font-stack-awesome;
            content: "\f105";
            font-weight: 600$mgod;
            font-size: 1.5rem;
            display: block;
            width: 20px;

            // position: absolute;
         }
      }

      span {
         position: relative;
         padding-left: 2rem;
         &:before {
            content: "";
            font-weight: 100;
            margin: 0 1rem;
            // color:#e3e3e3;
            position: absolute;
            left: 0;

            height: 100%;
            width: 1px;
            background-color: #e3e3e3;
         }
      }
   }

   &-body {
      font: 400 0.875rem/1.65 $font-stack-open;
      color:#595f7c;
      // padding: 1.75rem 5.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      
      p {
         &:last-child {
            margin-bottom: 0;
         }
      }
   }

   @media(min-width:576px) {
      &-header, &-body {}
      
      &-header {
         a {
            font-size: 1.25rem;
            padding: .35rem 1rem;
         }
         span {
            padding-left: 2.5rem;
         }
      }
      &-body {
         font-size: 1rem;
         padding: 1.75rem 5.5rem;
      }
   }
}

.active {
   .accordion {
      &-header {
         background-color: #0890fb;
         @include transition(0.1s);

         a {
            color:#fff;
            &:before {
               // @include transition(0.3s);
               content: "\f107";
            }
         }
      }
   }
}