.container {
   &-fluid {
      &-testimony {
         @media (min-width: 1200px) {
            background-color: $color-6;
            padding-bottom: 5rem;
         }
      }
   }
   &-testimonials {
      @media (min-width: 1200px) {
         &.bgblue {
            background: transparent; // Override
            margin-bottom: 0;
            margin-top: -10.625rem;
            &:before {
               background: url(/assets/images/bg-migration.jpg) 0 0 no-repeat;
               background-size: cover;
               position: absolute;
               height: 100%;
               content: "";
               right: 0;
               top: 0; // width: calc(50% + 465px);
               width: calc(50% + 555px);
               border-radius: 300px 0 0 300px;
            }
         }
      }

      // Styling
      .carousel {
         padding: 3rem 0 5rem 0;
         .section--title {
            font-size: 1.5rem;
            margin-bottom: 0;
            color: #fff;
            padding-top: 0;
            padding-bottom: 1.5rem;
         }
         .blockquote {
            margin: 0;
            p {
               font: 800 1.25rem $font-stack-open;
               color: #dedfe9;
               margin-bottom: 0;
               padding-left: 4rem;
               background-image: url(/assets/images/quotes.svg);
               background-repeat: no-repeat;
               background-position: 0 0;
               background-size: 40px;
               span {}
               cite {
                  font-weight: 300;
                  font-style: italic;
               }
            }
         }

         @media (min-width: 992px) {
            padding: 5rem 5rem 7rem 5rem;
         }
         @media (min-width: 576px) {
            .section--title {
               font-size: 2.5rem;
               padding-bottom: 2.5rem;
            }
            .blockquote {
               p {
                  font-size: 1.5rem;
                  padding-left: 6.25rem;
                  background-size: 65px;
               }
               span {
                  font-size: 1.125rem;
                  padding-top: .75rem;
                  display: block;
               }
            }
         }

         .carousel-indicators {
            bottom: 20px;

            @media (min-width: 768px) {
               bottom: 30px;
            }
         }
      }
   }
}