.navbar {

   .mob {
      &.account, &.cart { display: block; color:#2FDDEA}

      &.cart {
         
      }
      @media (min-width:576px){
         &.account, &.cart { display: none; }
      }
   }

   &-toggler {
      position: relative;
      top: 5px;
      @media (min-width:576px){
         top: 2px;
      }

      &:focus, &:active {
        outline: 0;
      }

      span {
        display: block;
        background-color: #092a44 ;
        height: 3px;
        width: 25px;
        margin-top: 4px;
        margin-bottom: 4px;
        transform: rotate(0deg);
        position: relative;
        left: 0;
        opacity: 1;
        &:nth-child(1), &:nth-child(3) {
          transition: transform .35s ease-in-out;
        }
      }

      @media (max-width: 575.98px) {
         &:not(.collapsed) span {
         &:nth-child(1) {
            position: relative;
            top: 17px;
            transform: rotate(135deg);
            opacity: 0.9;
         }
         &:nth-child(2) {
            height: 12px;
            visibility: hidden;
            background-color: transparent;
         }
         &:nth-child(3) {
            position: relative;
            top: -6px;
            transform: rotate(-135deg);
            opacity: 0.9;
         }
         }
      }
    }

   &-brand {
      padding: 0;
      margin: 0;

      @media (min-width:576px) {
         padding-top: .3125rem;
         padding-bottom: .3125rem;
         margin-right: 1rem;
      }

      .brand {
         text-align: center;
         // overflow: hidden;
         // width: 55px;
         margin: 0 auto;
         // padding-right: 1rem;

         @media(min-width: 576px) {
            width: auto;
            // overflow: inherit;
            padding-right: 0;
         }
         img {
            height: 42px;
            // height: 50px;
            margin-top: -5px;

            @media(min-width: 576px) {
               height: 70px;
               margin-top: 0;
            }
         }
      }
   }

   @media (max-width: 575.98px) {
      &-toggler {
         &:not(:disabled):not(.disabled) {
            padding: 0;
            border: 0;
         }
      }
   }

   padding-top: 0;
   padding-bottom: 0;

   background-color: #fff$mgod;
   border-bottom: 1px solid #e1eaf8;

   .nav {
      &-item {
         margin-left: .5rem;
         margin-right: .5rem;

         > a.nav-link {
            &:hover {
               color: #00DEED$mgod;
            }
         }

         &.show {
            a {
               &.dropdown-toggle {
                  @media (min-width: 1200px) {
                     // color: #00DEED$mgod;
                  }
               }
            }
         }

         @media (min-width:1200px) {
            &:first-child {
               margin-left: 0
            }

            &:last-child {
               margin-right: 0
            }
         }
      }

   }

   &-nav {
      &-mega {
         >  li {
            border-bottom: 3px solid transparent;

            >  a {
               font: 700 1.125rem $font-stack-open;
               color: #092a44$mgod;
               @include transition(0.3s);

               
               &:after {content: none}
               
               @media (min-width:1200px) {

                  line-height: 86px;
                  height: 86px;
   
                  padding-top:0;
                  padding-bottom:0;
               }
            }

            // On Dropdown
            &.dropdown {
               >  a {
                  position: relative;

                  span {
                     &:before {
                        content: "";
                        
                        padding: .25rem; /* Arrow size */
                        
                        // https://stackoverflow.com/questions/3012899/is-there-a-box-shadow-color-property
                        --box-shadow-color:#818EAE;
                        // --box-shadow-color:#092a44;
                        box-shadow: 1px -1px 0 1px var(--box-shadow-color) inset;
      
                        border: solid transparent;
                        
                        @include transition(0.3s);
                        transform:rotate(45deg);
                        
                        position: absolute;

                        top: 1.15rem;
                        right: 2.5rem;
                        @media (min-width:1200px) {
                           top: 2.35rem;  
                           right: -1px;
                           // right: 3px;
                        }
                     }

                  }

                  @media (min-width:1200px) {
                     padding-right: 1rem$mgod;
                     // padding-right: 1.25rem$mgod;

                     &:hover {
                        span {
                           &:before {
                              transform:rotate(-45deg);
                              top: 2.25rem;
                           }
                        }
                     }
                  }
               }

               &.show {
                  > a {
                     // color: #00DEED$mgod;
                     span {
                        &:before {
                           // --box-shadow-color:#DDE8F6;
                           --box-shadow-color:#00DEED;
                           @media(max-width: 1199.98px) {
                              transform:rotate(-45deg);
                              right: 2.75rem;
                              top: 1rem;
                           }
                        }
                     }
                  }
                  @media(min-width: 1200px) {
                     border-bottom-color: #00DEED;
                  }
               }
            }
         }
      }
   }

   .mob {

      &-navbar {display: none}

      @media (max-width: 575.98px) {
         &-navbar {
            width: 100%;           
            display: flex;
            flex-wrap: nowrap;
            margin: 0;
         }
         &-item {
            flex-basis: 100%;
            align-self: center;
            text-align: center;
            line-height: 70px;
            height: 70px;
            // border-right: 1px solid #e3e3e3;
            // &:last-child {border:0}
         }
      }
   }

   &-collapse {
      @media (max-width: 1199.98px) {
         border-top: 1px solid #e1eaf8;
         // margin: 5px -45px 0 -45px;
         margin: 0 -45px 0 -45px;

         .navbar {
            &-nav {
               .nav-item {
                  border-bottom: 1px dashed #e1eaf8;

                  padding-left: 0;
                  padding-right: 0;

                  margin-left: 0;
                  margin-right: 0;

                  &:last-child {
                     border-bottom: none;
                  }

                  >  a {
                     padding: 12px 2.75rem;

                     &:after {
                        right: 65px;
                     }
                  }

                  .dropdown-menu-mega {
                     >  .container {
                        padding-left: 0;
                        padding-right: 0;
                     }
                  }
               }
            }
         }
      }

      @media (max-width: 575.98px) {
         .navbar {
            &-nav {
               .nav-item {
                  >  a {
                     // padding-right: 3.875rem;
                     // padding-left: 3.875rem;
                  }
               }
            }
         }
      }
   }

   .support {
      display: block;
      padding: 1.875rem 3.875rem;
      margin-bottom: -5px;

      @media(min-width: 576px) {
         display: none;
      }

      h5,
      li,
      a {
         color: #fff;
      }

      h5 {
         font: 700 1.125rem $font-stack-rubik;
      }

      h5,
      li {
         margin-bottom: .875rem;
      }

      li {
         // margin-bottom: .5rem;
         font: 400 0.9375rem $font-stack-open;
         position: relative;
         padding-left: 1.95rem;

         &:last-child {
            margin-bottom: 0;
         }

         &:before {
            font-size: 1.3rem$mgod;
            color: #fff$mgod;
            position: absolute;
            left: 0;
         }
      }
   }
}

//  Mega Styling

.con {
   &--mega {
      @include transition(0.3s);

      background-color: transparent;

      &:hover {
         background-color: $color-6;
      }

      &-item {
         a {
            text-decoration: none;
         }

         .item {
            position: relative;

            h4 {
               font: 500 1rem $font-stack-rubik; // 1rem
               margin-bottom: 0;
               color: $color-5;
               letter-spacing: -.25px;
            }

            .desc {
               font-size: 0.8125rem;
               margin-bottom: 0;
               display: none;
            }

            &:before {
               content: "";
               background-repeat: no-repeat;
               background-position: 0 center;
               background-size: contain;

               position: absolute;
               left: 0;

               width: 100%;
               height: 30px;

               @media (min-width: 576px) {
                  height: 48px;
               }

               @media (min-width:1200px) {
                  height: 60px;
               }

            }


            &-hosting {
               &-web {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-hosting.svg);
                  }
               }

               &-transfer {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-hosting-transfer.svg);
                  }
               }

               &-ssl {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-ssl.svg);
                  }
               }
            }

            &-domain {
               &-name {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-name.svg);
                  }
               }

               &-transfer {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-transfer.svg);
                  }
               }
            }

            &-domain {
               &-name {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-name.svg);
                  }
               }

               &-transfer {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-transfer.svg);
                  }
               }
            }

            &-help {
               &-knowledge {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-kb.svg);
                  }
               }

               &-contact {
                  &:before {
                     background-image: url(/assets/images/mega-icon-bg-contact.svg);
                  }
               }
            }

            &-server {
               &-vps {
                  &:before {
                     background-image: url(/assets/images/vps-hosting-icon.svg);
                  }
               }

               &-wow {
                  &:before {
                     background-image: url(/assets/images/wow-hosting-icon.svg);
                  }
               }

               &-dedicated {
                  &:before {
                     background-image: url(/assets/images/dedicated-hosting-icon.svg);
                  }
               }
            }

            padding-left: 55px;

            display: flex;
            align-items: center;

            @media (min-width:576px) {
               padding-left: 85px;

               .desc {
                  display: block;
               }

               display: block;
            }

            @media (min-width:1200px) {
               padding-left: 100px;

               .desc {
                  font-size: 0.9375rem;
               }

               h4 {
                  font-size: 1.25rem;
                  margin-bottom: .6rem;
               }
            }

         }

         padding: 12px 2rem;

         margin-left: -3px;
         margin-right: -3px;

         @include border-radius(0);

         border-bottom: 1px dashed #fff;

         @media (min-width:1200px) {
            @include border-radius(9px);
            padding: 1.25rem;
            border-bottom: 0;
         }
      }

      @media (min-width:1200px) {
         background-color: #fff;
      }
   }
}

.dropdown {
   &-mega {
      position: static;

      &:after {}

      &.show {
         &:after {}
      }
   }

   &-menu {
      margin-top: 1.6rem$mgod; // temporary
      @include border-radius(0);

      &-mega {
         // width:100%;
         padding-bottom: 0;
         padding-top: 0;

         margin-top: 0$mgod;
         @include transition(.03s);

         border: 0;

         background-color: #e1eaf9;


         @media (min-width: 1200px) {
            width: 100%;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-left: none;
            border-right: none;
         }

      }
   }
}

.row {
   &--mega {
      background-color: transparent;

      [class^=col],
      [class^=col-] {

         &:last-child {
            .con--mega-item {
               border-bottom: 0;
            }
         }
      }

      @media (min-width: 1200px) {

         [class^=col],
         [class^=col-] {
            margin-top: 1.875rem;
            margin-bottom: 1.875rem;
         }
      }

      @media (min-width:992px) {}
   }
}

.info {
   ul {
      margin-top: .5rem;
      margin-bottom: .5rem;
   }

   a,
   li {
      font: 400 0.9375rem $font-stack-open;
      color: $color-4;
   }

   a {
      text-decoration: none
   }

   li {
      margin-right: 1.75rem$mgod;

      &:last-child {
         margin-right: 0$mgod;
      }

      &.info {

         &-phone,
         &-email,
         &-account,
         &-cart {
            &:before {
               font-family: "Font Awesome 5 Free";
               margin-right: 5px;
               font-weight: 900;
               font-size: 1rem;
               color: $color-1;
            }
         }

         &-phone {
            &:before {
               content: "\f590";
            }
         }

         &-email {
            &:before {
               content: "\f0e0";
            }
         }

         &-account {
            &:before {
               content: "\f007";
            }
         }

         &-cart {
            &:before {
               content: "\f07a";
            }
         }
      }
   }
}

.fa {
   &-layers {
      position: relative;
      &-counter {
         position: absolute;
         font-size: 9px;
         padding: 2px 3px;
         left: 10px;
         top: -10px;
         color:#fff;
         line-height: normal;
         border: 2px solid #fff;
         background-color: #ff2700;
         text-align: center;
         width: 20px;
         height: 20px;
         @include border-radius(90px);
      }
   }
}
