$cloud-top: #e1eaf8;
$cloud-bot: #fff;

.cloud {
   &-top, &-bot {
      svg {
         margin: 0 -2px;
      }
      > div {
         position: relative;
      }
   }

   &-inner {
      > .container {
         padding: 12rem 0;
      }
   }

   &-top {
      margin-bottom: -132px;
      > div {
         background: $cloud-top;
         margin-bottom: -1px;
      }
   }

   &-bot {
      margin-top: -132px;
      > div {
         margin-top: -1px;
         background: $cloud-bot;
      }
   }
}
