.contact-point {
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  .contact-24-7 {
    h4 {
      font: 700 1.875rem $font-stack-rubik;
      padding: 0;
    }

    span {
      font: 400 1.125rem $font-stack-open;
    }

    h4,
    span {
      color: #fff;
    }

    > div {
      padding-top: .65rem;
    }

    @media (max-width: 1199.98px) {
      > div {
        padding-top: 0;
        text-align: center;
        margin-bottom: 2.25rem;
      }
    }


    @media (max-width: 767.98px) {
      > div {
        margin: 0 -15rem 1.25rem;
      }
      h4 {font-size: 1.5rem;}
      span {font-size: 1rem;}
    }

  }

  .item {
    font: 400 1rem $font-stack-open;
    margin-right: 1.75rem;
    margin-bottom: .75rem;


    color: #fff;

    span {
      display: none;
    }

    strong {
      font: 700 1.5rem $font-stack-rubik;
    }


    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    &:before {
      border: 10px solid rgba(255, 255, 255, .25);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */

      display: inline-block;
      content: "";
      float: left;

      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      border-radius: 50px;

      padding: 2rem;
      margin-right: 0;
    }
    

    @media (min-width: 576px) {
      span {
        display: inline-block;
        padding-top: 1rem;
      }

      &:before {
        margin-right: .75rem;
      }
    }
    
    @media (min-width: 768px) {
      &:before {
        // margin-right: 1rem;
      }
    }

    &--mail {
      &:before {
        background-image: url(/assets/images/point-send-email.svg);
        background-size: 55% auto;
      }
    }

    &--call {
      &:before {
        background-image: url(/assets/images/point-call-us.svg);
        background-size: 40% auto;
      }
    }

    &--chat {
      &:before {
        background-image: url(/assets/images/point-chat-with-us.svg);
        background-size: 55% auto;
      }
    }
  }
}

.links-and-info {
  background-color: #181C2F;
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;
  position: relative;
  
  @media (max-width: 991.98px) { 
    padding-bottom: 2.75rem;
    padding-top: 2.75rem;
  }

  @media (max-width: 575.98px)  {
    background-color: #0c112a;
  }

  &:before {
    @media (min-width: 576px) {
      width: calc( 50% - 135px );
    }

    @media (max-width: 991.98px) { 
      width: calc( 50% - 35px );
    }

    // width: calc( 50% - 165px )$mgod;

    background: #0c112a;
    position: absolute;
    height: 100%;
    content: "";
    right: 0;
    top: 0;
  }

  > .container {
    &:first-child {
      .row {
        .internal-links {
          &:last-child {
            span.hostbarrel {
              background: url(/assets/images/hostbarrel2.svg) no-repeat right center/contain;
              display: inline-block;
              margin-bottom: 3.5rem;
              width: 100%;
              height: 70px;;
            }

            h4, h5 {
              font-family: $font-stack-rubik;
              font-weight: 700;
              white-space: nowrap;
            }
          
            h4 { color:#fff; font-size: 2.5rem; }
            h5 { color: $color-1; font-size: 1.5rem; }
          }
        }
      }
    }
  }

  .internal-links {
    ul {
      font-family: $font-stack-open;
      margin-bottom: 2rem;
  
      strong {
        text-transform: uppercase;
        font-size: 1rem;
        color:#fff;
        margin-bottom: .5rem;
        display: block;
      }
      li {
        margin-bottom: .35rem;
        &:last-child {
          margin-bottom: 0;
        }
  
        a {
          text-decoration: none;
          font-size: .875rem;
          color: #595f7c;
          &:hover {
            color:#fff;
          }
        }
      }
  
      &.payment-gateway {
        margin-bottom: 0;
  
        li {
          margin-bottom: 0;
          > i {
            color:#515771;
            font-size: 2.5rem;
          }
        }
      }

      &.social-media {
        margin-bottom: 0;

        > li {
          margin-bottom: 0;
          a {
            @include border-radius(5px);
            background-color: #181C2A;
            line-height: 3.375rem;
            display: inline-block;
            text-align: center;
            font-size: 1.5rem;
            height: 3.375rem;
            width: 3.375rem;
            color: #fff;
          }
        }
      }
    }
  }
}

.site-managed {
  p, a {
    color: #595f7c;
  }
  p {
    font: 400 .8125rem $font-stack-open;
    
    margin: 2rem 0;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .compliance {
    padding-bottom: 2.5rem;
    span {
      &.man {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 1rem;

        &--secure {
          background-image: url(/assets/images/man-secure_website.svg);
          width: 134px;
          height: 50px;
          margin-right: .5rem;
        }
        &--paypal {
          background-image: url(/assets/images/man-paypal-logo.svg);
          width: 180px;
          height: 50px;
        }
        &--pci {
          background-image: url(/assets/images/man-pci_dss_compliant.svg);
          width: 120px;
          height: 50px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .managed {
    @media (max-width: 575.98px) { 
      // padding: 0;
      p {
        span {
          display: inline-block;
        }
        margin-bottom: 1.25rem;
        // white-space: nowrap;
      }
    }
  }
}