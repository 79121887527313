
.with-ribbon {
  position: relative;

  overflow: hidden;
  
  // @media (min-width: 991.98px) {
  //   overflow: visible;
  // }

  .ribbon {
    position: absolute;
    right: -6px; top: -6px;
    z-index: 1;
    
    overflow: hidden;
   

    width: 135px; 
    height: 135px;
    text-align: right;

     span {
        font: 600 .75rem/1 $font-stack-open;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 40px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 170px;
        display: block;
        background: #181d35;
        box-shadow: 0 3px 10px -5px #333;
        position: absolute;
        top: 27px; right: -38px;

        &:before, &:after {
           content: "";
           position: absolute;
           z-index: 1;
           top: 100%;
           border: 0;
        }

        &:before {
          left: 0px; 
        }  
        &:after {
          right: 0px;  
        }

        @media (min-width:576px) {
          &:before {
            border-left: 3px solid #000;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #000;
          }

          &:after {
            border-left: 3px solid transparent;
            border-right: 3px solid #000;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #000;
          }
        }


     }
  }
}

.packages {
  background-color: $color-6;
  padding-top: 1.25rem;

  .table {
    thead {
      th {
        border-top:0;
      }
    }

    [class^="col-"], [class^="col"] {
      flex-shrink: 1;
    }
  }

  .package {
    // padding-bottom: 4rem;
    &.md {
      padding-bottom: 1.5rem;
      &-package { padding-bottom: 1.5rem; }
    }
    @media (min-width: 1200px) {
      padding-bottom: 19.35rem; 

      &.md {
        padding-bottom: 3.5rem;
        &-package { padding-bottom: 19.35rem; }
      }
    }
    
    a {text-decoration: none;}

    .plan {
      
      @media (min-width: 576px) {  
        padding-bottom: 1rem;

        @include transition(.1s ease-in);
        &:hover {
          transform: scale(1.025);
        }
      }

      &--domain {
        &:hover {
          transform: none;
        }

        .plan--wrapper {
          padding: 2.5rem;
          .jumbotron {
            overflow: hidden;
            position: relative;
            padding-bottom: 8rem$mgod;
            @include border-radius(9px);

            background: rgb(0,0,102);
            background: -moz-linear-gradient(90deg, rgba(0,0,102,1) 0%, rgba(0,51,153,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(0,0,102,1) 0%, rgba(0,51,153,1) 100%);
            background: linear-gradient(90deg, rgba(0,0,102,1) 0%, rgba(0,51,153,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000066",endColorstr="#003399",GradientType=1);

            .searchbar {z-index: 1;}

            &:after {
              content: "";
              background-image: url(/assets/images/domain-banner-icon.svg);
              background-repeat: no-repeat;
              width: 370px;
              height: 370px;
              position: absolute;
              top: 0;
              right: -2.8125rem;
            }
          }
        }
      }
      
      &--wrapper {
        padding: 0.9375rem 1.875rem;
        background-color: #fff;
        @include border-radius(9px);

        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
      }

      &--wrapper a.btn, &--specs li {
        font: 400 1rem $font-stack-open;
      }

      &--wrapper {
        a.btn {
          padding: .75rem 1rem;
          font-size: 1.125rem;
          font-weight: 600;
          color:#fff;
        }
      }

      &--specs {
        margin-top: 1.25rem;

        li {
          color: $color-9;
          border-top: 1px solid #e1eaf8;
          padding-top: .5rem;
          padding-bottom: .5rem;
          b {
            font-weight: 800;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }

        &-icon {
          li {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            b, strong, span {margin-right: 4px;}

            &:before {
              font-family: $font-stack-awesome;
              font-weight: 900;
              font-size: 1rem;
              margin-right:4px;
              color:#2FDDEA;
              width: 23px;
              content: "";
            }

            &.cpu {
              &:before {
                background-image: url(/assets/images/cpu-cores-icon.svg);
                background-size: 19px 19px;
                background-repeat: no-repeat;
                display: block;
                width: 19px;
                height: 19px;
                margin-right: 8px;
              }
            }

            &.ram {
              &:before {
                content: "\f538";
              }
            }

            &.hdd {
              &:before {
                content: "\f0a0";
              }
            }
            
            &.person {
              &:before {
                content: "\f007";
              }
            }
            
            &.exc {
              &:before {
                content: "\f362";
              }
            }

            &.shield {
              &:before {
                content: "\f3ed";
              }
            }

            &.globe {
              &:before {
                content: "\f0ac";
              }
            }

            &.ups {
              &:before {
                content: "\f35b";
              }
            }
            
            
            &.wow {
              display: inline-block;
              padding-left: 27px;
              position: relative;
              b, strong, span {margin: unset}

              i {
                position: absolute;
                font-weight: 600;
                top: .75rem;
                left: 0;
              }

              &:before {
                content: none;
              }
            }

            &.inline {
              padding-left: 27px;
              display: block;
              &:before {
                margin-left: -1.65rem;
                margin-right: .65rem;
              }
            }

            &.tip {
              span {
                border-bottom: 1px dashed #515771;
              }
            }
          }
        }
      }

      h6 { margin-bottom: 1rem}

      p,
      strong {
        font: 400 1rem/1.5rem $font-stack-open;
        margin-bottom: 1.5625rem;
        color: $color-9;

        &.price {
          font: 700 2.5rem $font-stack-rubik;
          margin-bottom: 1rem;
          color: $color-2;

          span:before, span.before {
            font-size: 1.25rem;
            position: relative;
            top: -.85rem;
            font-weight: 600;
          }

          span {
            &:before {
              content: "$";
            }

            &.before {
              &:before {
                content: "";
              }

              text-decoration: line-through;
              
              @media (max-width: 374.98px) { 
                position: static;
              }
            }
          }

          
          small {
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }

      span {
        &.icon {
          height: 70px;

          margin: .25rem 0 .6rem;

          // Homepage Hosting Packages
          &--basic {
            background-image: url(/assets/images/web-hosting-icon.svg);
          }
          &--wow {
            background-image: url(/assets/images/wow-hosting-icon.svg);
          } 
          &--vps {
            background-image: url(/assets/images/vps-hosting-icon.svg);
          } 
          &--dedicated {
            background-image: url(/assets/images/dedicated-hosting-icon.svg);
          }
          
          // Web Hosting Packages
          &--starter {
            background-image: url(/assets/images/web-starter-icon.svg);
          }
          &--standard {
            background-image: url(/assets/images/web-standard-icon.svg);
          }
          &--business {
            background-image: url(/assets/images/web-business-icon.svg);
          }
          &--business-pro {
            background-image: url(/assets/images/web-business-pro-icon.svg);
          }

          // SSL Packages
          &--ssl {
            background-image: url(/assets/images/standard-ssl.svg);
          }
          &--pre-ssl {
            background-image: url(/assets/images/premium-ssl.svg);
          }
         
          // WoW

          &--wow-basic {
            background-image: url(/assets/images/wow-hosting-icon.svg);
          }
          &--wow-pro {
            background-image: url(/assets/images/wow-pro.svg);
          }
          &--wow-mega {
            background-image: url(/assets/images/wow-mega.svg);
          }
          &--wow-ultra {
            background-image: url(/assets/images/wow-ultra.svg);
          }


          // VPS
          &--vps-basic {
            background-image: url(/assets/images/vpsEntry.svg);
          }
          &--vps-pro {
            background-image: url(/assets/images/vpsGO.svg);
          }
          &--vps-supreme {
            background-image: url(/assets/images/vpsUltra.svg);
          }
          &--vps-super {
            background-image: url(/assets/images/vpsUltra2.svg);
          }

          &--wow-item {
            height: 55px
          }
        }
      }

      strong {
        font-weight: 800;
        font-style: italic;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }

    &.compare {
      
      .row {
        &--content {
          margin-left: -5px;
          margin-right: -5px;

          [class^=col], [class^=col-] {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }

      .plan {
        h6 {
          margin-bottom: 0;
        }

        &--wrapper {
          a.btn {
            margin-bottom: .25rem;
          }
        }

        &--specs {
          margin-left: -1.875rem;
          margin-right: -1.875rem;
          
          li {
            font-size: 0.9375rem;
            &.price {
              font: 700 1.5rem $font-stack-rubik;

              span {
                &:before {
                  content:"$";
                  font-size: 0.875rem;
                  position: relative;
                  top: -.45rem;
                }
              }

              small {
                font-weight: 600;
              }
            }

            border-top: 1px dashed #e1eaf8;
            &:first-child {
              border-top: 2px solid #e1eaf8;  
            }
            &:last-child {
              border-bottom: 1px dashed #e1eaf8;  
            }
          }
        }
      }
    }
  }

  .btn {
    &-compare {
      font: 600 1.125rem $font-stack-open;
      padding: .7rem 1rem;
      i {
        margin-left: 3px;
      }
    }
  }
}

.contact-us {
  .btn {
     box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
     @include border-radius(60px);
     padding: 1.1875rem 4rem;
     border: 0;
  }

  li, span, a {
     font-size: 1.5rem;
     font-weight: 800;
     color: #FFF;
  }

  li {
     margin-bottom: 1rem;

     a {
        line-height: 0.5;
        letter-spacing: 1px;

        @media(min-width: 576px) {
           display: inline-table;
        }
     }

     span {color:$color-1}

     @media(min-width: 576px) {
        margin-right: 1.25rem;
        margin-bottom: 0;
     }

     &:last-child {
        margin-right: 0;
     }
  }

  &.basic {
    a {
      padding: 1.1875rem 0;
      @include box-shadow(none);
      @include border-radius(0);
    }

    a, span { color:#0890fb; }
    li {
      color:#092a44;
      &:first-child {
        margin-right: 5px;
      }
    }
  }

  
}
