body {
  color: $primary-color;
  padding-top: 70px;
  overflow-x: hidden;

  &:before {
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 110;
    visibility: hidden;
    opacity: 0;
    @include transition(all .5s ease-in-out);
  }

  &.dropdown-active {
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (min-width: 768px) { 
    padding-top: 80px
  }

  @media(min-width: 1200px) {
    padding-top: 90px;
  }
}

.form-control:focus {
  border-color: #aaa;
}

.btn, .form-control, a  {
  &:focus, &.focus, &:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}


.icon {
  &:before, &:after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.check-square {
  &:before {
    font-family: $font-stack-awesome;
    content: "\f14a";
  }
}

.check-square {
  &-solid {
    li {
      &:before {
        font-weight: 900;
      }
    }
  }

  &-solid li, li {
    &:before {
      font-family: $font-stack-awesome;
      content: "\f14a";
    }
  }
}


.block {
  &--padding {
    padding: 3.125rem 0;
  
    @media(min-width: 576px) { padding: 5rem 0; }
  }
}

.wrapper {
  &-inline {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;

    &-content-1 {
      padding-bottom: 18.75%;
    }
  }
}

.select-container {
  position: relative;

  .select-arrow {
    color: $color-4;
    right: 0px;
    top: 17px;
    width: 30px;
    position: absolute;
    display: block;
    z-index: 10;
    margin: 0 0 0 0;
    pointer-events: none;
  }
}

.row {
  &--content {
    margin-right: -10px;
    margin-left: -10px;
  
    [class^=col], [class^=col-] {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width:1200px) {}
  }
}

.item-title {
  font: 700 1.5rem $font-stack-rubik;
  color: $color-4;
}

.section {
  &--title {
    font: 700 1.5rem $font-stack-rubik;
    padding: 2.5rem 0;
    color: $color-4;
  }

  &--wrapper {
    .block {
      &--title {
        margin: 0;
        padding-top: 0;
        padding-bottom: 2rem; 
        font: 700 2.5rem $font-stack-rubik;
      }
    }


    p, span {
      font-family: $font-stack-open;
    }
    p {
      margin-bottom: 2rem;
      &:last-child {
        margin: 0;
      }
    }
  }

  @media(min-width: 576px) {
    &--title {

      font-size: 2.5rem;
    }
  }
}


.content {
  &-fh {
    height: 100%;
  }

  .title {
    font: 700 1.5rem $font-stack-rubik;
    margin: 0;

    &-sub {
      
      font-size: 1.125rem;
      display: block;
      width: 100%;
    }
  }
  p, span, li {
    font-family: $font-stack-open;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1rem;
    &:last-child {
      margin: 0;
    }
  }

  &.list {
    ul {
      color:#092a44;
      padding: 0 1rem;
      
      .list-title {
        font: 700 1.125rem $font-stack-rubik;
        margin-bottom: 2rem;
        display: inline-block;
        width: 100%;
      }
    }

    li {
      padding-left: 35px;
      position: relative;
      margin: 0 0 1.35rem;
      // font-size: 1rem;
      font: 400 1rem $font-stack-rubik;

      &:before {
        display: inline-block;
        position: absolute;
        color: #1BDDEE;
        left: 0;
      }
      &:last-child {
        margin: 0;
      }
    }

    .fg {
      &-white {
        color: #fff;
      }
    }
  }

 
  @media(min-width: 576px) {
    .title {
      font-size: 2.5rem;
      padding: 0 0 2rem;
      &-sub {
        font-size: 1.5rem;
      }
    }    

    p {
      font-size: 1.125rem;
      line-height: 1.85rem;
    }

    &.list {
      ul {
        .list-title {
          font-size: 1.5rem; 
        }
      }

      li {
        font-size: 1.25rem;
      }
    }

  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // # border: 1px solid green;
   -webkit-text-fill-color: #092a44;
  // # -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  // # transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}


.desc {
    font: 400 1rem/1.6rem $font-stack-open;
    margin-bottom: 1.5625rem;
    color: $color-9;
}

span {
  &.icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: inline-block;
    width: 100%;
  }
}



.bgblue {
  background-image: url(/assets/images/bg-migration.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
}

.bg {
  &-blue { 
    background-image: url(/assets/images/gradient-blue.jpg);  
  }
  &-white {background-color: #fff}
  &-pale {background-color: #e1eaf8}
}

.barrel {
  @media (min-width: 1200px) {
    background-repeat: no-repeat$mgod;

    &-white-bg {
      background-image: url(/assets/images/whitebarrel.png);
      background-position: calc(100vw - 465px) bottom;
      .package {
        // padding-bottom: 20.375rem$mgod;

        &-pb4 {
          // padding-bottom: 4rem$mgod;
        }
      }
    }

    &-pale-bg {
      background: url(/assets/images/palebarrel.png);
      background-position: -50px bottom;
    }
  }
}

.rsb {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}



.img {
  &-res-fix {
    &:not(.png) {
      width: 100%;
      padding: 0 1rem;
    }
  }
}


.carousel-indicators li {
  width: 14px;
  height: 14px;
  margin: 0 4px;
  background: transparent;
  border: 1px solid $color-1;
  
  @include border-radius(100px);

  &.active {
    background-color: $color-1;
  }
}

.gradien-orange {
  background-image: linear-gradient(to right, #ff5910, #ff6c0f, #ff7d13, #ff8c1a, #ff9b24);
  margin-bottom:-2px;
  h4 {
    font: 500 1.125rem $font-stack-open;
    padding: 1.125rem 0;
    margin-bottom: 0;
    color: #fff;
  }
}

.card {
  
  &-border-none {border:0}
  &-9 {
    @include border-radius(9px);
  }  
}

.btn {
  &-gradient {
    background: linear-gradient(90deg, #ff5910 0%, #ff9b24 100%);
    
    position: relative;
    z-index: 5;

    display: inline-table;

    &:before {
      background-image: linear-gradient(90deg, #ff5910 0%, #ff5910 100%);
      @include transition(opacity .15s linear);
      border-radius: inherit;
      position: absolute;
      z-index: -5;
      content: '';
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      opacity: 0;
    }
    
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &-bold {
    font: 800 1.125rem $font-stack-open;
    // line-height: inherit;

    padding: .65rem 2rem;

    letter-spacing: 1px;
    color: #fff;
    border: 0;
    width: 100%;
    display: block;

    @media(min-width: 576px) {
      font-size: 1.5rem;
      padding-right: 4rem;
      padding-left: 4rem;
      width: unset;
      display: inline;
    }

    &:hover {
      color:#fff;
    }
  }  
}

.radius {
  &-60 {
    @include border-radius(60px);
  }
}

.dollar {
  &:before {
    // content: "$";
  }
}

.container {
  &.info {

  }
}



.color {
  &-0 { color: $color-0 }
  &-1 { color: $color-1 }
  &-2 { color: $color-2 }
  &-3 { color: $color-3 }
  &-4 { color: $color-4 }
  &-5 { color: $color-5 }
  &-6 { color: $color-6 }
  &-7 { color: $color-7 }
  &-8 { color: $color-8 }
  &-9 { color: $color-9 }
  &-10 { color: $color-10 }
  &-11 { color: $color-11 }

  &-bg {
    &-2 {
      background-color: $color-2;
    }

    &-3 {
      background-color: $color-3;
    }

    &-5 {
      background-color: $color-5;
    }
    &-6 {
      background-color: $color-6;
    }
  }
}


.font {
  &-s-1 { font-size: 1.125rem; } // 18px
  &-s-2 { font-size: 1.5rem; } // 24px
  &-s-3 { font-size: 2.5rem; } //40px
  &-s-4 { font-size: 1.25rem; } //20px
  &-s-5 { font-size: 1.875rem; } //30px
  &-s-6 { font-size: 1.75rem; } //30px
  &-s-7 { font-size: 1rem; } //30px
  &-s-8 { font-size: 0.9375rem; } //30px

  &-w {
    &-3 {
      font-weight: 300;
    }
    &-4 {
      font-weight: 400;
    }
    &-5 {
      font-weight: 500;
    }
    &-6 {
      font-weight: 600;
    }
    &-7 {
      font-weight: 700;
    }
    &-8 {
      font-weight: 800;
    }
  }

  &-h {
    &-1b {
      line-height: 1.95rem;
    }

    &-2 {
      line-height: 2rem;
    }
  }

  &-open {
    font-family: $font-stack-open;
  }
  &-rubik {
    font-family: $font-stack-rubik;
  }
}



.space {
  &-p {
    &t {
      &-5 {
        padding-top: 5rem;
      }
    }

    &b {
      &-5 {
        padding-bottom: 5rem;
      }
    }
  }
}

.btn {
  display: block;
  text-decoration: none;

  &--n {
    &-1 {
      color:#1BDDEE;
    }
  }
  
  &--h {
    &-1 {
      &:hover {color:#1BDDEE}
    }
  }

  &--tu {
    &:hover {
      text-decoration: underline;
    }
  }

  &--bg {
    &-1 {
      background-color: $color-3;
      &:hover {
        background-color: $color-2;
      }
    }
  }
}

.font-open {
  font-family: $font-stack-open;
}
.font-rubik {
  font-family: $font-stack-rubik;
}
